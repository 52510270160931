@import url('https://fonts.googleapis.com/css?family=Permanent+Marker');



$main-background: url(http://ramauto.co/wp-content/uploads/2018/08/printable-comic-book-pages-plus-best-strip-template-ideas-on-superhero-printables.jpg) ;

// .App {
//     // background: $main-background;
//     // background-attachment: fixed;
//     // height: 100vh;
//     // width: 100vw;
//     font-family: 'Permanent Marker', cursive;
// }

body{
    background: $main-background;
    background-attachment: fixed;
    font-family: 'Permanent Marker', cursive;
    height: 100vh;
    width: 100vw;
}
body::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
}

.App{
    height: 100vh;
    width: 100vw;
    // background: linear-gradient(0.25turn,rgb(0, 0, 0),rgba(135, 135, 135, 0.262),black);
    background: rgba(20, 25, 30, 0.81);
}
.App::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
}



@media (min-width: 426px) and (max-width: 768px){
    body{
        background: $main-background;
        background-attachment: fixed;
        font-family: 'Permanent Marker', cursive;
        height: 100vh;
        width: 100vw;
    }
    .App{
        height: 100vh;
        width: 100vw;
        // background: linear-gradient(0.25turn,rgb(0, 0, 0),rgba(135, 135, 135, 0.262),black);
        background: rgba(20, 25, 30, 0.81);
    }
}
// @media (min-width: 320px) and (max-width: 424px){
//     body{
//         background: $main-background;
//         background-attachment: fixed;
//         font-family: 'Permanent Marker', cursive;
//         height: 80vh;
//         width: 80vw;
//     }
//     .App{
//         height:112vh;
//         width: 112vw;
//         // background: linear-gradient(0.25turn,rgb(0, 0, 0),rgba(135, 135, 135, 0.262),black);
//         background: rgba(20, 25, 30, 0.81);        
//     }
// }

@media screen and (max-width: 425px){
    body{
        background: $main-background;
        background-attachment: fixed;
        font-family: 'Permanent Marker', cursive;
        height: 100vh;
        width: 100vw;
    }
    .App{
        height:100vh;
        width: 100vw;
        // background: linear-gradient(0.25turn,rgb(0, 0, 0),rgba(135, 135, 135, 0.262),black);
        background: rgba(20, 25, 30, 0.81);        
    }
}