@import url(https://fonts.googleapis.com/css?family=Permanent+Marker);
@import url(https://fonts.googleapis.com/css?family=Permanent+Marker);
@import url(https://fonts.googleapis.com/css?family=Permanent+Marker);
@import url(https://fonts.googleapis.com/css?family=Permanent+Marker);
@import url(https://fonts.googleapis.com/css?family=Permanent+Marker);
@import url(https://fonts.googleapis.com/css?family=Permanent+Marker);
@import url(https://fonts.googleapis.com/css?family=Permanent+Marker);
@import url(https://fonts.googleapis.com/css?family=Permanent+Marker);
@import url(https://fonts.googleapis.com/css?family=Permanent+Marker);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

body {
  background: url(http://ramauto.co/wp-content/uploads/2018/08/printable-comic-book-pages-plus-best-strip-template-ideas-on-superhero-printables.jpg);
  background-attachment: fixed;
  font-family: 'Permanent Marker', cursive;
  height: 100vh;
  width: 100vw; }

body::-webkit-scrollbar {
  width: 0px;
  background: transparent;
  /* make scrollbar transparent */ }

.App {
  height: 100vh;
  width: 100vw;
  background: rgba(20, 25, 30, 0.81); }

.App::-webkit-scrollbar {
  width: 0px;
  background: transparent;
  /* make scrollbar transparent */ }

@media (min-width: 426px) and (max-width: 768px) {
  body {
    background: url(http://ramauto.co/wp-content/uploads/2018/08/printable-comic-book-pages-plus-best-strip-template-ideas-on-superhero-printables.jpg);
    background-attachment: fixed;
    font-family: 'Permanent Marker', cursive;
    height: 100vh;
    width: 100vw; }
  .App {
    height: 100vh;
    width: 100vw;
    background: rgba(20, 25, 30, 0.81); } }

@media screen and (max-width: 425px) {
  body {
    background: url(http://ramauto.co/wp-content/uploads/2018/08/printable-comic-book-pages-plus-best-strip-template-ideas-on-superhero-printables.jpg);
    background-attachment: fixed;
    font-family: 'Permanent Marker', cursive;
    height: 100vh;
    width: 100vw; }
  .App {
    height: 100vh;
    width: 100vw;
    background: rgba(20, 25, 30, 0.81); } }

.homep {
  display: flex;
  align-items: center;
  margin: 20px 80px 0 80px;
  height: 80vh;
  max-width: 100vw;
  overflow-x: scroll;
  overflow-y: visible; }

.homep::-webkit-scrollbar {
  width: 0px;
  background: transparent;
  /* make scrollbar transparent */ }

.homec {
  margin: 0 100px; }

@media (min-width: 426px) and (max-width: 768px) {
  .homep {
    display: flex;
    align-items: center;
    margin: 10px 40px 0 40px;
    max-width: 100vw;
    overflow-x: scroll;
    overflow-y: visible; }
  .homec {
    margin: 0 60px; } }

@media screen and (max-width: 425px) {
  .homep {
    display: flex;
    align-items: center;
    margin: 20px 5px 0 5px;
    max-width: 100vw;
    overflow-x: scroll;
    overflow-y: visible; }
  .homec {
    margin: 0 5px; } }

.postp {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 600px;
  width: 500px;
  margin: 0 auto;
  background: rgba(90, 95, 110, 0.748);
  box-shadow: 15px 15px 10px rgba(0, 0, 0, 0.33);
  -webkit-animation: appearpost 1s ease-in-out 0s 1;
          animation: appearpost 1s ease-in-out 0s 1; }

@-webkit-keyframes appearpost {
  0% {
    -webkit-transform: translate(-300px, 0);
            transform: translate(-300px, 0); }
  50% {
    -webkit-transform: translate(50px, 0);
            transform: translate(50px, 0); }
  100% { } }

@keyframes appearpost {
  0% {
    -webkit-transform: translate(-300px, 0);
            transform: translate(-300px, 0); }
  50% {
    -webkit-transform: translate(50px, 0);
            transform: translate(50px, 0); }
  100% { } }

.postc {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .postc .postcc {
    margin-bottom: 20px;
    font-size: 2rem;
    text-shadow: 5px 5px 1px rgba(20, 25, 30, 0.81);
    color: white; }
  .postc img {
    height: 500px;
    width: 450px; }
  .postc .deleteBtn, .postc .likebtn, .postc .unlikebtn {
    cursor: pointer;
    font-size: 10px;
    background: rgba(0, 0, 0, 0);
    box-shadow: 2px 2px 1px white;
    text-shadow: 2px 2px 1px rgba(255, 255, 255, 0.459);
    border: none;
    border-radius: 5px 5px;
    color: white;
    font-family: 'Permanent Marker', cursive;
    width: 20px;
    height: 20px;
    -webkit-transition: .8s;
    transition: .8s;
    -webkit-transform: translate(0px, -1px);
            transform: translate(0px, -1px); }
  .postc .deleteBtn:hover, .postc .likebtn:hover, .postc .unlikebtn:hover {
    font-size: 10px;
    background: rgba(0, 0, 0, 0.858);
    box-shadow: 2px 2px 1px white;
    text-shadow: 2px 2px 1px rgba(255, 255, 255, 0.459);
    border: none;
    border-radius: 5px 5px;
    color: white;
    font-family: 'Permanent Marker', cursive;
    width: 20px;
    height: 20px;
    -webkit-transition: .8s;
    transition: .8s; }
  .postc .footerdiv {
    height: 10px;
    width: 450px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    font-family: 'Permanent Marker', cursive;
    text-shadow: 2px 2px 1px rgba(20, 25, 30, 0.81); }
  .postc .likebtn, .postc .unlikebtn {
    height: 0;
    width: 0;
    font-size: 20px;
    border: none;
    box-shadow: none;
    margin-left: 5px;
    -webkit-transform: translate(0, -1px);
            transform: translate(0, -1px); }
  .postc .likebtn:hover, .postc .unlikebtn:hover {
    background: none;
    box-shadow: none;
    font-size: 20px;
    -webkit-transform: scale(2);
            transform: scale(2); }
  .postc .postpages {
    margin-right: 10px; }

.mobiledelete {
  display: none; }

#deleteBtnM {
  position: absolute;
  display: none; }

@media (min-width: 425px) and (max-width: 768px) {
  .postp {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 550px;
    width: 400px;
    margin: 0 auto;
    box-shadow: 15px 15px 10px rgba(0, 0, 0, 0.33);
    -webkit-animation: appearpost 1s ease-in-out 0s 1;
            animation: appearpost 1s ease-in-out 0s 1;
    overflow: hidden; }
  .postc {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-shadow: 3px 3px 2px rgba(0, 0, 0, 0.33); }
    .postc .postcc {
      margin-bottom: 20px; }
    .postc img {
      height: 350px;
      width: 300px;
      margin-bottom: 0; } }

@media screen and (max-width: 425px) {
  .postp {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 440px;
    width: 300px;
    margin: 0 auto;
    box-shadow: 15px 15px 10px rgba(0, 0, 0, 0.33);
    -webkit-animation: appearpost 1s ease-in-out 0s 1;
            animation: appearpost 1s ease-in-out 0s 1; }
  .postc {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    text-shadow: 3px 3px 2px rgba(0, 0, 0, 0.33); }
    .postc .postpages {
      -webkit-transform: translate(-40px, 0);
              transform: translate(-40px, 0); }
    .postc .postcc {
      margin-bottom: 20px;
      font-size: 20px; }
    .postc img {
      height: 350px;
      width: 280px; }
    .postc .deleteBtn, .postc .likebtn, .postc .unlikebtn {
      cursor: pointer;
      font-size: 10px;
      background: black;
      box-shadow: 2px 2px 1px white;
      text-shadow: 2px 2px 1px rgba(255, 255, 255, 0.459);
      border: none;
      border-radius: 5px 5px;
      color: white;
      font-family: 'Permanent Marker', cursive;
      width: 20px;
      height: 20px;
      -webkit-transition: .8s;
      transition: .8s; }
    .postc .likebtn, .postc .unlikebtn {
      font-size: 12px; }
    .postc > .footerdiv {
      width: 300px; }
      .postc > .footerdiv span {
        margin-left: 10px;
        margin-right: 0; }
  .mobiledelete {
    display: block; }
  #deleteBtnM {
    cursor: pointer;
    font-size: 10px;
    background: black;
    box-shadow: 2px 2px 1px white;
    text-shadow: 2px 2px 1px rgba(255, 255, 255, 0.459);
    border: none;
    border-radius: 5px 5px;
    color: white;
    font-family: 'Permanent Marker', cursive;
    width: 20px;
    height: 20px;
    -webkit-transition: .8s;
    transition: .8s;
    position: static;
    display: block;
    -webkit-transform: translate(-11px, 0);
            transform: translate(-11px, 0); } }

.aboutp {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 600px;
  width: 500px;
  margin: 0 auto;
  background: rgba(90, 95, 110, 0.748);
  box-shadow: 15px 15px 10px rgba(0, 0, 0, 0.33);
  -webkit-animation: appearab 1s ease-in-out 0s 1;
          animation: appearab 1s ease-in-out 0s 1; }

@-webkit-keyframes appearab {
  0% {
    height: 0;
    width: 0; }
  100% {
    height: 600px;
    width: 500px; } }

@keyframes appearab {
  0% {
    height: 0;
    width: 0; }
  100% {
    height: 600px;
    width: 500px; } }

.aboutc {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-shadow: 3px 3px 2px rgba(0, 0, 0, 0.33); }
  .aboutc > div {
    height: 40px; }
  .aboutc .abouth1 {
    font-size: 3rem;
    text-shadow: 5px 5px 1px rgba(20, 25, 30, 0.81);
    color: white;
    -webkit-animation: appearabcd 1s ease-in-out 0s 1;
            animation: appearabcd 1s ease-in-out 0s 1; }

@-webkit-keyframes appearabcd {
  0% {
    font-size: 0px; }
  80% {
    font-size: 0px; } }

@keyframes appearabcd {
  0% {
    font-size: 0px; }
  80% {
    font-size: 0px; } }
  .aboutc p {
    text-align: center;
    font-size: 24px;
    padding: 0 30px;
    margin-bottom: 60px;
    text-shadow: 5px 5px 1px rgba(20, 25, 30, 0.81);
    color: white;
    -webkit-animation: appearabc 1s ease-in-out 0s 1;
            animation: appearabc 1s ease-in-out 0s 1; }

@-webkit-keyframes appearabc {
  0% {
    font-size: 0px; }
  80% {
    font-size: 0px; } }

@keyframes appearabc {
  0% {
    font-size: 0px; }
  80% {
    font-size: 0px; } }
  .aboutc .contactbtn {
    margin-top: 10px;
    font-size: 20px;
    background: rgba(0, 0, 0, 0.858);
    box-shadow: 2px 2px 1px white;
    text-shadow: 2px 2px 1px rgba(255, 255, 255, 0.459);
    border: none;
    border-radius: 10px 10px;
    color: white;
    font-family: 'Permanent Marker', cursive;
    width: 100px;
    height: 30px;
    -webkit-transition: .8s;
    transition: .8s;
    -webkit-animation: appearabcde 1s ease-in-out 0s 1;
            animation: appearabcde 1s ease-in-out 0s 1; }

@-webkit-keyframes appearabcde {
  0% {
    -webkit-transform: translate(0, 1000px);
            transform: translate(0, 1000px);
    height: 0;
    width: 0;
    font-size: 0px; }
  80% {
    height: 0;
    width: 0;
    font-size: 0px;
    -webkit-transform: translate(0, 1000px);
            transform: translate(0, 1000px); } }

@keyframes appearabcde {
  0% {
    -webkit-transform: translate(0, 1000px);
            transform: translate(0, 1000px);
    height: 0;
    width: 0;
    font-size: 0px; }
  80% {
    height: 0;
    width: 0;
    font-size: 0px;
    -webkit-transform: translate(0, 1000px);
            transform: translate(0, 1000px); } }
  .aboutc .contactbtn:hover {
    width: 110px;
    height: 40px; }

@media (min-width: 425px) and (max-width: 768px) {
  .aboutp {
    height: 550px;
    width: 400px;
    -webkit-animation: appearab 1s ease-in-out 0s 1;
            animation: appearab 1s ease-in-out 0s 1; }
  @-webkit-keyframes appearab {
    0% {
      height: 0;
      width: 0;
      font-size: 0px; }
    80% {
      font-size: 0px; }
    100% {
      height: 550px;
      width: 400px; } }
  @keyframes appearab {
    0% {
      height: 0;
      width: 0;
      font-size: 0px; }
    80% {
      font-size: 0px; }
    100% {
      height: 550px;
      width: 400px; } }
  .aboutc .abouth1 {
    font-size: 2rem;
    text-shadow: 5px 5px 1px rgba(20, 25, 30, 0.81); }
  .aboutc p {
    font-size: 20px; } }

@media screen and (max-width: 425px) {
  .aboutp {
    height: 450px;
    width: 300px;
    -webkit-animation: appearab 1s ease-in-out 0s 1;
            animation: appearab 1s ease-in-out 0s 1; }
  @-webkit-keyframes appearab {
    0% {
      height: 0;
      width: 0;
      font-size: 0px; }
    80% {
      font-size: 0px; }
    100% {
      height: 450px;
      width: 300px; } }
  @keyframes appearab {
    0% {
      height: 0;
      width: 0;
      font-size: 0px; }
    80% {
      font-size: 0px; }
    100% {
      height: 450px;
      width: 300px; } }
  .aboutc .abouth1 {
    font-size: 2rem; }
  .aboutc p {
    font-size: 18px; } }

.loginp {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 600px;
  width: 500px;
  margin: 0 auto;
  background: rgba(90, 95, 110, 0.748);
  box-shadow: 15px 15px 10px rgba(0, 0, 0, 0.33);
  -webkit-animation: appearlog 1s ease-in-out 0s 1;
          animation: appearlog 1s ease-in-out 0s 1; }
  .loginp h1 {
    font-size: 3rem;
    text-shadow: 5px 5px 1px rgba(20, 25, 30, 0.81);
    color: white;
    -webkit-animation: appearlogabc 1s ease-in-out 0s 1;
            animation: appearlogabc 1s ease-in-out 0s 1; }

@-webkit-keyframes appearlogabc {
  0% {
    font-size: 0; }
  80% {
    font-size: 0; } }

@keyframes appearlogabc {
  0% {
    font-size: 0; }
  80% {
    font-size: 0; } }

@-webkit-keyframes appearlog {
  0% {
    bottom: 0;
    height: 0;
    width: 0;
    font-size: 0px;
    border-radius: 50%; }
  80% {
    font-size: 0px; }
  100% {
    height: 600px;
    width: 500px; } }

@keyframes appearlog {
  0% {
    bottom: 0;
    height: 0;
    width: 0;
    font-size: 0px;
    border-radius: 50%; }
  80% {
    font-size: 0px; }
  100% {
    height: 600px;
    width: 500px; } }

.loginc {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-shadow: 5px 5px 1px rgba(20, 25, 30, 0.81);
  color: white;
  font-size: 24px;
  margin-bottom: 150px;
  -webkit-animation: appearlogabcd 1s ease-in-out 0s 1;
          animation: appearlogabcd 1s ease-in-out 0s 1; }

@-webkit-keyframes appearlogabcd {
  0% {
    font-size: 0; }
  80% {
    font-size: 0; } }

@keyframes appearlogabcd {
  0% {
    font-size: 0; }
  80% {
    font-size: 0; } }
  .loginc input {
    margin-bottom: 10px;
    font-size: 20px;
    height: 25px;
    width: 180px;
    border: none;
    border-top-right-radius: 5px 5px;
    border-bottom-left-radius: 5px 5px;
    background: rgba(80, 80, 80, 0.639);
    color: white;
    box-shadow: 2px 2px 1px white;
    font-family: 'Permanent Marker', cursive;
    -webkit-animation: appearloga 1s ease-in-out 0s 1;
            animation: appearloga 1s ease-in-out 0s 1; }

@-webkit-keyframes appearloga {
  0% {
    -webkit-transform: translate(0, 1000px);
            transform: translate(0, 1000px);
    height: 0;
    width: 0; }
  80% {
    -webkit-transform: translate(0, 1000px);
            transform: translate(0, 1000px);
    height: 0;
    width: 0; } }

@keyframes appearloga {
  0% {
    -webkit-transform: translate(0, 1000px);
            transform: translate(0, 1000px);
    height: 0;
    width: 0; }
  80% {
    -webkit-transform: translate(0, 1000px);
            transform: translate(0, 1000px);
    height: 0;
    width: 0; } }
  .loginc button {
    cursor: pointer;
    margin-top: 20px;
    font-size: 20px;
    background: rgba(0, 0, 0, 0.858);
    box-shadow: 2px 2px 1px white;
    text-shadow: 2px 2px 1px rgba(255, 255, 255, 0.459);
    border: none;
    border-radius: 10px 10px;
    color: white;
    font-family: 'Permanent Marker', cursive;
    width: 100px;
    height: 30px;
    -webkit-transition: .8s;
    transition: .8s;
    -webkit-animation: appearlogab 1s ease-in-out 0s 1;
            animation: appearlogab 1s ease-in-out 0s 1; }

@-webkit-keyframes appearlogab {
  0% {
    -webkit-transform: translate(0, 1000px);
            transform: translate(0, 1000px);
    height: 0;
    width: 0; }
  80% {
    -webkit-transform: translate(0, 1000px);
            transform: translate(0, 1000px);
    height: 0;
    width: 0; } }

@keyframes appearlogab {
  0% {
    -webkit-transform: translate(0, 1000px);
            transform: translate(0, 1000px);
    height: 0;
    width: 0; }
  80% {
    -webkit-transform: translate(0, 1000px);
            transform: translate(0, 1000px);
    height: 0;
    width: 0; } }
  .loginc button:hover {
    width: 130px;
    height: 40px; }
  .loginc .fpbtn {
    cursor: pointer;
    z-index: 0;
    margin: 0 5px;
    background: rgba(0, 0, 0, 0);
    box-shadow: 2px 2px 1px white;
    text-shadow: 2px 2px 1px rgba(255, 255, 255, 0.459);
    border: none;
    border-radius: 5px 5px;
    color: white;
    font-family: 'Permanent Marker', cursive;
    -webkit-transition: .8s;
    transition: .8s;
    font-size: 12px;
    width: 130px;
    height: 30px;
    -webkit-transform: translate(0, -15px);
            transform: translate(0, -15px); }
  .loginc .fpbtn:hover {
    background: rgba(0, 0, 0, 0.858);
    width: 130px;
    height: 30px; }
  .loginc .hideFP {
    display: none; }
  .loginc .showFP {
    height: 220vh;
    width: 100vw;
    position: absolute;
    background-color: rgba(26, 26, 46, 0.794);
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center; }

@media (min-width: 425px) and (max-width: 768px) {
  .loginp {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 550px;
    width: 400px;
    margin: 0 auto;
    background: rgba(90, 95, 110, 0.748);
    box-shadow: 15px 15px 10px rgba(0, 0, 0, 0.33);
    -webkit-animation: appearlog 1s ease-in-out 0s 1;
            animation: appearlog 1s ease-in-out 0s 1;
    overflow: hidden; }
    .loginp h1 {
      font-size: 3rem;
      text-shadow: 2px 2px 1px white; }
  @-webkit-keyframes appearlog {
    0% {
      bottom: 0;
      height: 0;
      width: 0;
      font-size: 0px;
      border-radius: 50%; }
    80% {
      font-size: 0px; }
    100% {
      height: 550px;
      width: 400px;
      overflow: hidden; } }
  @keyframes appearlog {
    0% {
      bottom: 0;
      height: 0;
      width: 0;
      font-size: 0px;
      border-radius: 50%; }
    80% {
      font-size: 0px; }
    100% {
      height: 550px;
      width: 400px;
      overflow: hidden; } }
  .loginc {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-shadow: 3px 3px 2px rgba(0, 0, 0, 0.33);
    margin-bottom: 150px; }
    .loginc input {
      margin-bottom: 10px;
      border: none;
      border-top-right-radius: 5px 5px;
      border-bottom-left-radius: 5px 5px;
      background: rgba(80, 80, 80, 0.639);
      color: white;
      box-shadow: 2px 2px 1px white;
      font-family: 'Permanent Marker', cursive; }
    .loginc button {
      margin-top: 20px;
      font-size: 20px;
      background: rgba(0, 0, 0, 0.858);
      box-shadow: 2px 2px 1px white;
      text-shadow: 2px 2px 1px rgba(255, 255, 255, 0.459);
      border: none;
      border-radius: 10px 10px;
      color: white;
      font-family: 'Permanent Marker', cursive;
      width: 100px;
      height: 30px;
      -webkit-transition: .8s;
      transition: .8s; }
    .loginc button:hover {
      width: 130px;
      height: 40px; } }

@media screen and (max-width: 425px) {
  .loginp {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 450px;
    width: 300px;
    margin: 0 auto;
    background: rgba(90, 95, 110, 0.748);
    box-shadow: 15px 15px 10px rgba(0, 0, 0, 0.33);
    -webkit-animation: appearlog 1s ease-in-out 0s 1;
            animation: appearlog 1s ease-in-out 0s 1;
    overflow: hidden; }
    .loginp h1 {
      font-size: 2rem; }
  @-webkit-keyframes appearlog {
    0% {
      bottom: 0;
      height: 0;
      width: 0;
      font-size: 0px;
      border-radius: 50%; }
    80% {
      font-size: 0px; }
    100% {
      height: 450px;
      width: 300px;
      overflow: hidden; } }
  @keyframes appearlog {
    0% {
      bottom: 0;
      height: 0;
      width: 0;
      font-size: 0px;
      border-radius: 50%; }
    80% {
      font-size: 0px; }
    100% {
      height: 450px;
      width: 300px;
      overflow: hidden; } }
  .loginc {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-shadow: 3px 3px 2px rgba(0, 0, 0, 0.33);
    margin-bottom: 150px; }
    .loginc .showFP {
      height: 100vh;
      width: 100vw;
      -webkit-transform: translate(0, 30px);
              transform: translate(0, 30px); }
    .loginc input {
      margin-bottom: 10px;
      width: 140px;
      border: none;
      border-top-right-radius: 5px 5px;
      border-bottom-left-radius: 5px 5px;
      background: rgba(80, 80, 80, 0.639);
      color: white;
      box-shadow: 2px 2px 1px white;
      font-family: 'Permanent Marker', cursive; }
    .loginc button {
      margin-top: 20px;
      font-size: 20px;
      background: rgba(0, 0, 0, 0.858);
      box-shadow: 2px 2px 1px white;
      text-shadow: 2px 2px 1px rgba(255, 255, 255, 0.459);
      border: none;
      border-radius: 10px 10px;
      color: white;
      font-family: 'Permanent Marker', cursive;
      width: 100px;
      height: 30px;
      -webkit-transition: .8s;
      transition: .8s; }
    .loginc button:hover {
      width: 130px;
      height: 40px; } }

.forgotpasswordp {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 600px;
  width: 500px;
  margin: 0 auto;
  background: rgba(90, 95, 110, 0.748);
  box-shadow: 15px 15px 10px rgba(0, 0, 0, 0.33);
  -webkit-animation: appearfp 1s ease-in-out 0s 1;
          animation: appearfp 1s ease-in-out 0s 1; }
  .forgotpasswordp h1 {
    font-size: 3rem;
    text-shadow: 5px 5px 1px rgba(20, 25, 30, 0.81);
    color: white;
    margin-bottom: 50px;
    -webkit-animation: appearfph1 1s ease-in-out 0s 1;
            animation: appearfph1 1s ease-in-out 0s 1; }

@-webkit-keyframes appearfph1 {
  0% {
    font-size: 0px; }
  80% {
    font-size: 0px; } }

@keyframes appearfph1 {
  0% {
    font-size: 0px; }
  80% {
    font-size: 0px; } }

@-webkit-keyframes appearfp {
  0% {
    height: 0px;
    width: 0px; }
  100% {
    height: 600px;
    width: 500px; } }

@keyframes appearfp {
  0% {
    height: 0px;
    width: 0px; }
  100% {
    height: 600px;
    width: 500px; } }

.forgotpasswordc {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-shadow: 3px 3px 2px rgba(0, 0, 0, 0.33);
  -webkit-animation: appearfpc 1s ease-in-out 0s 1;
          animation: appearfpc 1s ease-in-out 0s 1; }

@-webkit-keyframes appearfpc {
  0% {
    font-size: 0px; }
  80% {
    font-size: 0px; } }

@keyframes appearfpc {
  0% {
    font-size: 0px; }
  80% {
    font-size: 0px; } }

.exitfp, .submitfp {
  cursor: pointer;
  margin-top: 20px;
  font-size: 20px;
  background: rgba(0, 0, 0, 0.858);
  box-shadow: 2px 2px 1px white;
  text-shadow: 2px 2px 1px rgba(255, 255, 255, 0.459);
  border: none;
  border-radius: 10px 10px;
  color: white;
  font-family: 'Permanent Marker', cursive;
  width: 100px;
  height: 30px;
  -webkit-transition: .8s;
  transition: .8s; }

.exitdiv {
  position: absolute;
  -webkit-transform: translate(0px, 310px);
          transform: translate(0px, 310px); }

.btndiv {
  height: 40px; }

@media screen and (max-width: 425px) {
  .forgotpasswordp {
    height: 450px;
    width: 300px; }
    .forgotpasswordp h1 {
      font-size: 2rem; }
  @-webkit-keyframes appearfp {
    0% {
      height: 0px;
      width: 0px; }
    100% {
      height: 450px;
      width: 300px; } }
  @keyframes appearfp {
    0% {
      height: 0px;
      width: 0px; }
    100% {
      height: 450px;
      width: 300px; } }
  .exitdiv {
    position: absolute;
    -webkit-transform: translate(0px, 232px);
            transform: translate(0px, 232px); } }

.signupp {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 600px;
  width: 500px;
  margin: 0 auto;
  background: rgba(90, 95, 110, 0.748);
  box-shadow: 15px 15px 10px rgba(0, 0, 0, 0.33);
  -webkit-animation: appearsign 1s ease-in-out 0s 1;
          animation: appearsign 1s ease-in-out 0s 1; }
  .signupp h1 {
    font-size: 3rem;
    text-shadow: 5px 5px 1px rgba(20, 25, 30, 0.81);
    color: white;
    margin-bottom: 50px;
    -webkit-animation: appearsigna 1s ease-in-out 0s 1;
            animation: appearsigna 1s ease-in-out 0s 1; }

@-webkit-keyframes appearsigna {
  0% {
    font-size: 0px; }
  80% {
    font-size: 0px; } }

@keyframes appearsigna {
  0% {
    font-size: 0px; }
  80% {
    font-size: 0px; } }

@-webkit-keyframes appearsign {
  0% {
    height: 0px;
    width: 0px; }
  100% {
    height: 600px;
    width: 500px; } }

@keyframes appearsign {
  0% {
    height: 0px;
    width: 0px; }
  100% {
    height: 600px;
    width: 500px; } }

.signupc {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-shadow: 3px 3px 2px rgba(0, 0, 0, 0.33);
  -webkit-animation: appearsignab 1s ease-in-out 0s 1;
          animation: appearsignab 1s ease-in-out 0s 1; }

@-webkit-keyframes appearsignab {
  0% {
    font-size: 0px; }
  80% {
    font-size: 0px; } }

@keyframes appearsignab {
  0% {
    font-size: 0px; }
  80% {
    font-size: 0px; } }
  .signupc .signcc {
    height: 420px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    font-size: 24px;
    color: white;
    text-shadow: 5px 5px 1px rgba(20, 25, 30, 0.81);
    margin: 0 20px;
    -webkit-animation: appearsignac 1s ease-in-out 0s 1;
            animation: appearsignac 1s ease-in-out 0s 1; }

@-webkit-keyframes appearsignac {
  0% {
    font-size: 0px; }
  80% {
    font-size: 0px; } }

@keyframes appearsignac {
  0% {
    font-size: 0px; }
  80% {
    font-size: 0px; } }
    .signupc .signcc input, .signupc .signcc textarea {
      margin-bottom: 20px;
      font-size: 20px;
      border: none;
      border-top-right-radius: 5px 5px;
      border-bottom-left-radius: 5px 5px;
      background: rgba(80, 80, 80, 0.639);
      color: white;
      box-shadow: 2px 2px 1px white;
      font-family: 'Permanent Marker', cursive;
      -webkit-animation: appearsignabc 1s ease-in-out 0s 1;
              animation: appearsignabc 1s ease-in-out 0s 1; }

@-webkit-keyframes appearsignabc {
  0% {
    -webkit-transform: translate(0, 1000px);
            transform: translate(0, 1000px);
    height: 0;
    width: 0; }
  80% {
    -webkit-transform: translate(0, 1000px);
            transform: translate(0, 1000px);
    height: 0;
    width: 0; } }

@keyframes appearsignabc {
  0% {
    -webkit-transform: translate(0, 1000px);
            transform: translate(0, 1000px);
    height: 0;
    width: 0; }
  80% {
    -webkit-transform: translate(0, 1000px);
            transform: translate(0, 1000px);
    height: 0;
    width: 0; } }
    .signupc .signcc input {
      height: 20px;
      width: 150px; }
    .signupc .signcc textarea {
      width: 150px; }
    .signupc .signcc .submitForm, .signupc .signcc .resetForm {
      cursor: pointer;
      font-size: 20px;
      background: rgba(0, 0, 0, 0.858);
      box-shadow: 2px 2px 1px white;
      text-shadow: 2px 2px 1px rgba(255, 255, 255, 0.459);
      border: none;
      border-radius: 10px 10px;
      color: white;
      font-family: 'Permanent Marker', cursive;
      width: 100px;
      height: 30px;
      margin-bottom: 30px;
      -webkit-transition: .8s;
      transition: .8s;
      -webkit-animation: appearsignabcd 1s ease-in-out 0s 1;
              animation: appearsignabcd 1s ease-in-out 0s 1; }

@-webkit-keyframes appearsignabcd {
  0% {
    -webkit-transform: translate(0, 1000px);
            transform: translate(0, 1000px);
    height: 0;
    width: 0; }
  80% {
    -webkit-transform: translate(0, 1000px);
            transform: translate(0, 1000px);
    height: 0;
    width: 0; } }

@keyframes appearsignabcd {
  0% {
    -webkit-transform: translate(0, 1000px);
            transform: translate(0, 1000px);
    height: 0;
    width: 0; }
  80% {
    -webkit-transform: translate(0, 1000px);
            transform: translate(0, 1000px);
    height: 0;
    width: 0; } }
    .signupc .signcc .submitForm:hover, .signupc .signcc .resetForm:hover {
      width: 130px;
      height: 40px; }
    .signupc .signcc .resetForm {
      cursor: pointer;
      height: 30px;
      width: 70px; }
    .signupc .signcc .buttonDivSpace {
      height: 50px; }

@media (min-width: 425px) and (max-width: 768px) {
  .signupp {
    height: 550px;
    width: 400px;
    -webkit-animation: appearsign 1s ease-in-out 0s 1;
            animation: appearsign 1s ease-in-out 0s 1; }
  @-webkit-keyframes appearsign {
    0% {
      height: 0px;
      width: 0px;
      font-size: 0px;
      border-radius: 50%; }
    80% {
      font-size: 0px; }
    100% {
      height: 550px;
      width: 400px; } }
  @keyframes appearsign {
    0% {
      height: 0px;
      width: 0px;
      font-size: 0px;
      border-radius: 50%; }
    80% {
      font-size: 0px; }
    100% {
      height: 550px;
      width: 400px; } }
  .signupc .signcc {
    font-size: 18px; }
    .signupc .signcc input, .signupc .signcc textarea {
      margin-bottom: 10px; } }

@media screen and (max-width: 425px) {
  .signupp {
    height: 450px;
    width: 300px;
    -webkit-animation: appearsign 1s ease-in-out 0s 1;
            animation: appearsign 1s ease-in-out 0s 1; }
    .signupp h1 {
      font-size: 2rem;
      margin-top: 0;
      margin-bottom: 0; }
  @-webkit-keyframes appearsign {
    0% {
      height: 0px;
      width: 0px;
      font-size: 0px;
      border-radius: 50%; }
    80% {
      font-size: 0px; }
    100% {
      height: 450px;
      width: 300px; } }
  @keyframes appearsign {
    0% {
      height: 0px;
      width: 0px;
      font-size: 0px;
      border-radius: 50%; }
    80% {
      font-size: 0px; }
    100% {
      height: 450px;
      width: 300px; } }
  .signupc .signcc {
    font-size: 16px; }
    .signupc .signcc label {
      text-align: center; }
    .signupc .signcc input, .signupc .signcc textarea {
      width: 100px; } }

.createepicp {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 600px;
  width: 500px;
  margin: 0 auto;
  background: rgba(90, 95, 110, 0.748);
  box-shadow: 15px 15px 10px rgba(0, 0, 0, 0.33);
  -webkit-animation: appear 1s ease-in-out 0s 1;
          animation: appear 1s ease-in-out 0s 1;
  overflow: hidden; }

@-webkit-keyframes appear {
  0% {
    height: 0;
    width: 0; }
  100% {
    height: 600px;
    width: 500px; } }

@keyframes appear {
  0% {
    height: 0;
    width: 0; }
  100% {
    height: 600px;
    width: 500px; } }

.createepicc, .upload {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-shadow: 3px 3px 2px rgba(0, 0, 0, 0.33);
  text-align: center;
  font-size: 20px; }
  .createepicc .createh1, .upload .createh1 {
    font-size: 3rem;
    text-shadow: 5px 5px 1px rgba(20, 25, 30, 0.81);
    text-align: center;
    color: white; }
  .createepicc .createspan, .upload .createspan {
    font-size: 28px;
    text-shadow: 5px 5px 1px rgba(20, 25, 30, 0.81);
    color: white; }
  .createepicc input, .upload input {
    margin-bottom: 10px;
    text-align: center;
    border: none;
    border-top-right-radius: 5px 5px;
    border-bottom-left-radius: 5px 5px;
    background: rgba(80, 80, 80, 0.639);
    color: white;
    box-shadow: 2px 2px 1px white;
    height: 50px;
    width: 400px;
    font-size: 2rem;
    font-family: 'Permanent Marker', cursive; }
  .createepicc .upload-button, .upload .upload-button {
    cursor: pointer;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 20px;
    background: rgba(0, 0, 0, 0.858);
    box-shadow: 2px 2px 1px white;
    text-shadow: 2px 2px 1px rgba(255, 255, 255, 0.459);
    border: none;
    border-radius: 10px 10px;
    color: white;
    font-family: 'Permanent Marker', cursive;
    width: 150px;
    height: 30px;
    -webkit-transition: .8s;
    transition: .8s; }
  .createepicc .upload-button:hover, .upload .upload-button:hover {
    width: 180px;
    height: 40px; }
  .createepicc .upload, .upload .upload {
    color: rgba(255, 255, 255, 0.486); }

@media (min-width: 426px) and (max-width: 768px) {
  .createepicp {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 550px;
    width: 400px;
    margin: 0 auto;
    background: rgba(90, 95, 110, 0.748);
    box-shadow: 15px 15px 10px rgba(0, 0, 0, 0.33);
    -webkit-animation: appear 1s ease-in-out 0s 1;
            animation: appear 1s ease-in-out 0s 1;
    overflow: hidden; }
  @-webkit-keyframes appear {
    0% {
      height: 0;
      width: 0; }
    100% {
      height: 550px;
      width: 400px; } }
  @keyframes appear {
    0% {
      height: 0;
      width: 0; }
    100% {
      height: 550px;
      width: 400px; } }
  .createepicc {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-shadow: 3px 3px 2px rgba(0, 0, 0, 0.33); }
    .createepicc .createh1 {
      font-size: 3rem;
      text-shadow: 2px 2px 1px white;
      text-align: center; }
    .createepicc input {
      margin-bottom: 10px;
      border: none;
      border-top-right-radius: 5px 5px;
      border-bottom-left-radius: 5px 5px;
      background: rgba(80, 80, 80, 0.639);
      color: white;
      box-shadow: 2px 2px 1px white;
      height: 30px;
      width: 300px;
      font-size: 2rem;
      font-family: 'Permanent Marker', cursive; } }

@media screen and (max-width: 425px) {
  .createepicp {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 450px;
    width: 300px;
    margin: 0 auto;
    background: rgba(90, 95, 110, 0.748);
    box-shadow: 15px 15px 10px rgba(0, 0, 0, 0.33);
    -webkit-animation: appear 1s ease-in-out 0s 1;
            animation: appear 1s ease-in-out 0s 1;
    overflow: hidden; }
  @-webkit-keyframes appear {
    0% {
      height: 0;
      width: 0; }
    100% {
      height: 450px;
      width: 300px; } }
  @keyframes appear {
    0% {
      height: 0;
      width: 0; }
    100% {
      height: 450px;
      width: 300px; } }
  .createepicc {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-shadow: 3px 3px 2px rgba(0, 0, 0, 0.33); }
    .createepicc .createh1 {
      font-size: 2rem;
      text-align: center; }
    .createepicc .createspan {
      font-size: 1.5rem; }
    .createepicc input {
      margin-bottom: 10px;
      border: none;
      border-top-right-radius: 5px 5px;
      border-bottom-left-radius: 5px 5px;
      background: rgba(80, 80, 80, 0.639);
      color: white;
      box-shadow: 2px 2px 1px white;
      height: 30px;
      width: 160px;
      font-size: 2rem;
      font-family: 'Permanent Marker', cursive; }
    .createepicc br {
      display: none; } }

.profilep {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 600px;
  width: 500px;
  margin: 0 auto;
  background: rgba(90, 95, 110, 0.748);
  box-shadow: 15px 15px 10px rgba(0, 0, 0, 0.33);
  -webkit-animation: appearpro 1s ease-in-out 0s 1;
          animation: appearpro 1s ease-in-out 0s 1; }

@-webkit-keyframes appearpro {
  0% {
    height: 0px;
    width: 0px;
    font-size: 0px;
    border-radius: 50%; }
  80% {
    font-size: 0px; }
  100% {
    height: 600px;
    width: 500px; } }

@keyframes appearpro {
  0% {
    height: 0px;
    width: 0px;
    font-size: 0px;
    border-radius: 50%; }
  80% {
    font-size: 0px; }
  100% {
    height: 600px;
    width: 500px; } }

.profilec, .profilec .profileInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-shadow: 5px 5px 1px rgba(20, 25, 30, 0.81);
  color: white;
  font-size: 24px; }
  .profilec h3, .profilec .profileInfo h3, .profilec h4, .profilec .profileInfo h4 {
    margin: 10px auto 0 auto; }
  .profilec div > div, .profilec .profileInfo div > div {
    text-align: center; }
  .profilec .profileInfo {
    flex-direction: row;
    justify-content: space-evenly;
    overflow-y: scroll;
    max-height: 300px;
    width: 450px; }
  .profilec .postcc {
    margin-bottom: 20px; }
  .profilec #mobilePicture {
    display: none; }
  .profilec .diamond, .profilec .dia {
    margin: 0 auto;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    overflow: hidden;
    width: 200px;
    height: 200px; }
  .profilec .diamond {
    -webkit-transform: rotate(45deg) translateY(-25px) translateX(-25px);
            transform: rotate(45deg) translateY(-25px) translateX(-25px); }
  .profilec .diamond .dia {
    width: 380px;
    height: 400px;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }
  .profilec .diamond img {
    width: 100%;
    height: auto;
    position: relative;
    left: 7px;
    top: -100px; }
  .profilec .editpic {
    cursor: pointer;
    z-index: 1000;
    font-size: 10px;
    background: rgba(0, 0, 0, 0);
    box-shadow: 2px 2px 1px white;
    text-shadow: 2px 2px 1px rgba(255, 255, 255, 0.459);
    border: none;
    border-radius: 5px 5px;
    color: white;
    font-family: 'Permanent Marker', cursive;
    width: 40px;
    height: 20px;
    -webkit-transition: .8s;
    transition: .8s;
    -webkit-transform: translate(0px, -80px);
            transform: translate(0px, -80px); }
  .profilec .editpic:hover {
    font-size: 10px;
    background: rgba(0, 0, 0, 0.858);
    box-shadow: 2px 2px 1px white;
    text-shadow: 2px 2px 1px rgba(255, 255, 255, 0.459);
    border: none;
    border-radius: 5px 5px;
    color: white;
    font-family: 'Permanent Marker', cursive;
    width: 40px;
    height: 20px;
    -webkit-transition: .8s;
    transition: .8s; }
  .profilec .ceditpic {
    cursor: pointer;
    z-index: 0;
    font-size: 10px;
    margin: 0 5px;
    background: rgba(0, 0, 0, 0);
    box-shadow: 2px 2px 1px white;
    text-shadow: 2px 2px 1px rgba(255, 255, 255, 0.459);
    border: none;
    border-radius: 5px 5px;
    color: white;
    font-family: 'Permanent Marker', cursive;
    width: 50px;
    height: 20px;
    -webkit-transition: .8s;
    transition: .8s; }
  .profilec .ceditpic:hover {
    font-size: 10px;
    background: rgba(0, 0, 0, 0.858);
    box-shadow: 2px 2px 1px white;
    text-shadow: 2px 2px 1px rgba(255, 255, 255, 0.459);
    border: none;
    border-radius: 5px 5px;
    color: white;
    font-family: 'Permanent Marker', cursive;
    width: 50px;
    height: 20px;
    -webkit-transition: .8s;
    transition: .8s; }
  .profilec .cceditpic {
    cursor: pointer;
    z-index: 0;
    font-size: 10px;
    margin: 0 5px;
    background: rgba(0, 0, 0, 0);
    box-shadow: 2px 2px 1px white;
    text-shadow: 2px 2px 1px rgba(255, 255, 255, 0.459);
    border: none;
    border-radius: 5px 5px;
    color: white;
    font-family: 'Permanent Marker', cursive;
    width: 20px;
    height: 20px;
    -webkit-transition: .8s;
    transition: .8s; }
  .profilec .cceditpic:hover {
    font-size: 10px;
    background: rgba(0, 0, 0, 0.858);
    box-shadow: 2px 2px 1px white;
    text-shadow: 2px 2px 1px rgba(255, 255, 255, 0.459);
    border: none;
    border-radius: 5px 5px;
    color: white;
    font-family: 'Permanent Marker', cursive;
    width: 20px;
    height: 20px;
    -webkit-transition: .8s;
    transition: .8s; }
  .profilec input, .profilec .profileInfo input {
    height: 20px;
    width: 150px;
    font-size: 20px;
    border: none;
    border-top-left-radius: 5px 5px;
    border-bottom-right-radius: 5px 5px;
    background: rgba(80, 80, 80, 0.639);
    color: white;
    box-shadow: 2px 2px 1px white;
    font-family: 'Permanent Marker', cursive; }
  .profilec input::-webkit-input-placeholder, .profilec .profileInfo input::-webkit-input-placeholder {
    color: white; }
  .profilec input::-ms-input-placeholder, .profilec .profileInfo input::-ms-input-placeholder {
    color: white; }
  .profilec input::placeholder, .profilec .profileInfo input::placeholder {
    color: white; }
  .profilec .editdiv {
    display: flex;
    justify-content: center;
    align-items: center; }
  .profilec .hideContent {
    display: none; }
  .profilec .showContent, .profilec .profileEdit {
    height: 220vh;
    width: 100vw;
    position: absolute;
    background-color: rgba(26, 26, 46, 0.794);
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center; }
    .profilec .showContent .showContentc, .profilec .profileEdit .showContentc {
      position: absolute;
      z-index: 2;
      height: 80vh;
      width: 80vw;
      background: rgba(90, 95, 110, 0.748);
      margin-top: 100px;
      display: flex;
      flex-direction: column;
      align-items: center;
      overflow-y: scroll; }
      .profilec .showContent .showContentc hr, .profilec .profileEdit .showContentc hr {
        width: 500px;
        color: white; }
      .profilec .showContent .showContentc > .showContentcc, .profilec .profileEdit .showContentc > .showContentcc {
        height: 300px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly; }
    .profilec .showContent .showContentc::-webkit-scrollbar, .profilec .profileEdit .showContentc::-webkit-scrollbar {
      width: 0px;
      background: transparent;
      /* make scrollbar transparent */ }
  .profilec .toggleContent, .profilec .toggleContent2 {
    cursor: pointer;
    width: 100px;
    height: 30px;
    margin: 0 100px;
    font-size: 20px;
    background: rgba(0, 0, 0, 0.858);
    box-shadow: 2px 2px 1px white;
    text-shadow: 2px 2px 1px rgba(255, 255, 255, 0.459);
    border: none;
    border-radius: 10px 10px;
    color: white;
    font-family: 'Permanent Marker', cursive;
    -webkit-transition: .8s;
    transition: .8s;
    overflow: hidden;
    margin: 10px;
    -webkit-transform: translate(0, 18px);
            transform: translate(0, 18px); }
  .profilec .toggleContent:hover, .profilec .toggleContent2:hover {
    width: 220px; }
  .profilec .toggleContent2 {
    position: absolute;
    z-index: 3;
    -webkit-transform: translate(0, 510px);
            transform: translate(0, 510px); }
  .profilec .toggleContent3 {
    cursor: pointer;
    z-index: 0;
    margin: 0 5px;
    background: rgba(0, 0, 0, 0);
    box-shadow: 2px 2px 1px white;
    text-shadow: 2px 2px 1px rgba(255, 255, 255, 0.459);
    border: none;
    border-radius: 5px 5px;
    color: white;
    font-family: 'Permanent Marker', cursive;
    -webkit-transition: .8s;
    transition: .8s;
    font-size: 12px;
    width: 60px;
    margin: 10px auto 0 auto;
    -webkit-transform: translate(0, 10px);
            transform: translate(0, 10px); }
  .profilec .toggleContent3:hover {
    background: rgba(0, 0, 0, 0.858); }
  .profilec .profileEditClosed {
    display: none; }

#mobileContentBtn {
  display: none; }

@media (min-width: 426px) and (max-width: 768px) {
  .profilep {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 550px;
    width: 400px;
    margin: 0 auto;
    background: rgba(90, 95, 110, 0.748);
    box-shadow: 15px 15px 10px rgba(0, 0, 0, 0.33);
    -webkit-animation: appearpro 1s ease-in-out 0s 1;
            animation: appearpro 1s ease-in-out 0s 1; }
  @-webkit-keyframes appearpro {
    0% {
      height: 0px;
      width: 0px;
      font-size: 0px;
      border-radius: 50%; }
    80% {
      font-size: 0px; }
    100% {
      height: 550px;
      width: 400px; } }
  @keyframes appearpro {
    0% {
      height: 0px;
      width: 0px;
      font-size: 0px;
      border-radius: 50%; }
    80% {
      font-size: 0px; }
    100% {
      height: 550px;
      width: 400px; } }
  .profilec, .profilec .profileInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-shadow: 3px 3px 2px rgba(0, 0, 0, 0.33); }
    .profilec .postcc {
      margin-bottom: 20px; } }

@media screen and (max-width: 425px) {
  .profilep {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 450px;
    width: 300px;
    margin: 0 auto;
    background: rgba(90, 95, 110, 0.748);
    box-shadow: 15px 15px 10px rgba(0, 0, 0, 0.33);
    -webkit-animation: appearpro 1s ease-in-out 0s 1;
            animation: appearpro 1s ease-in-out 0s 1;
    overflow: hidden; }
    .profilep h1 {
      font-size: 3rem;
      text-shadow: 2px 2px 1px white; }
  @-webkit-keyframes appearpro {
    0% {
      height: 0px;
      width: 0px;
      font-size: 0px;
      border-radius: 50%; }
    80% {
      font-size: 0px; }
    100% {
      height: 450px;
      width: 300px; } }
  @keyframes appearpro {
    0% {
      height: 0px;
      width: 0px;
      font-size: 0px;
      border-radius: 50%; }
    80% {
      font-size: 0px; }
    100% {
      height: 450px;
      width: 300px; } }
  .profilec, .profilec .profileInfo {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-shadow: 3px 3px 2px rgba(0, 0, 0, 0.33); }
    .profilec > .diamond, .profilec .profileInfo > .diamond, .profilec .dia {
      display: none; }
    .profilec .diamond {
      display: none; }
    .profilec .diamond .dia {
      display: none; }
    .profilec > .diamond img, .profilec .profileInfo > .diamond img {
      display: none; }
    .profilec #mobilePicture {
      display: block;
      height: 130px;
      width: 130px;
      border-radius: 50%;
      margin-bottom: 10px; }
    .profilec #profileEdit {
      height: 100vh;
      width: 100vw;
      -webkit-transform: translate(0, -90px);
              transform: translate(0, -90px); }
    .profilec .showContent, .profilec .profileEdit {
      height: 100vh;
      width: 100vw;
      align-items: flex-start;
      -webkit-transform: translate(0, -90px);
              transform: translate(0, -90px); }
      .profilec .showContent > .showContentc, .profilec .profileInfo .showContent > .showContentc, .profilec .profileEdit > .showContentc, .profilec .profileInfo .profileEdit > .showContentc {
        margin-top: 40px;
        display: block; }
      .profilec .showContent > .toggleContent2, .profilec .profileInfo .showContent > .toggleContent2, .profilec .profileEdit > .toggleContent2, .profilec .profileInfo .profileEdit > .toggleContent2 {
        display: block; }
  .profileInfo {
    flex-direction: column;
    overflow-y: scroll;
    max-height: 400px;
    width: 280px; }
  .profileBio {
    flex-direction: column;
    max-height: 400px;
    width: 280px; }
  #mobileContentBtn {
    display: block;
    margin: 0 auto 10px auto;
    cursor: pointer;
    width: 100px;
    height: 30px;
    background: rgba(0, 0, 0, 0);
    box-shadow: 2px 2px 1px white;
    text-shadow: 2px 2px 1px rgba(255, 255, 255, 0.459);
    border: none;
    border-radius: 5px 5px;
    color: white;
    font-family: 'Permanent Marker', cursive;
    -webkit-transition: .8s;
    transition: .8s;
    font-size: 12px;
    -webkit-transform: translate(0, 18px);
            transform: translate(0, 18px); }
  .toggleContent, .profilec .toggleContent2 {
    display: none; } }

.profileContentP {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 250px;
  width: 250px;
  background: rgba(30, 29, 54, 0.575);
  margin: 20px 15px; }
  .profileContentP .profileContentC, .profileContentP .profileContentC > div {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center; }
    .profileContentP .profileContentC > h2, .profileContentP .profileContentC > div > h2 {
      margin: 0; }
    .profileContentP .profileContentC > img, .profileContentP .profileContentC > div > img, .profileContentP .profileContentC > .contentPageImg, .profileContentP .profileContentC > div > .contentPageImg {
      height: 150px;
      width: 150px;
      margin-bottom: 20px; }
    .profileContentP .profileContentC > .contentPageImg, .profileContentP .profileContentC > div > .contentPageImg {
      margin-bottom: 0; }
    .profileContentP .profileContentC > div, .profileContentP .profileContentC > div > div {
      justify-content: center; }
      .profileContentP .profileContentC > div > p, .profileContentP .profileContentC > div > div > p {
        margin: 10px;
        font-size: 12px; }

.submitForm {
  cursor: pointer;
  font-size: 20px;
  background: rgba(0, 0, 0, 0.858);
  box-shadow: 2px 2px 1px white;
  text-shadow: 2px 2px 1px rgba(255, 255, 255, 0.459);
  border: none;
  border-radius: 10px 10px;
  color: white;
  font-family: 'Permanent Marker', cursive;
  width: 100px;
  height: 30px;
  margin-bottom: 30px;
  -webkit-transition: .8s;
  transition: .8s;
  -webkit-animation: appearsignabcd 1s ease-in-out 0s 1;
          animation: appearsignabcd 1s ease-in-out 0s 1; }

@-webkit-keyframes appearsignabcd {
  0% {
    -webkit-transform: translate(0, 1000px);
            transform: translate(0, 1000px);
    height: 0;
    width: 0; }
  80% {
    -webkit-transform: translate(0, 1000px);
            transform: translate(0, 1000px);
    height: 0;
    width: 0; } }

@keyframes appearsignabcd {
  0% {
    -webkit-transform: translate(0, 1000px);
            transform: translate(0, 1000px);
    height: 0;
    width: 0; }
  80% {
    -webkit-transform: translate(0, 1000px);
            transform: translate(0, 1000px);
    height: 0;
    width: 0; } }

.submitForm:hover {
  width: 130px;
  height: 30px; }

#editProfilePlaceholder::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.25); }

#editProfilePlaceholder::-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.25); }

#editProfilePlaceholder::placeholder {
  color: rgba(255, 255, 255, 0.25); }

@media screen and (max-width: 425px) {
  #mobileEditProfile {
    height: 70vh;
    margin-top: 60px; }
  #editProfileH1 {
    font-size: 2rem;
    text-shadow: none;
    margin-bottom: 20px; }
  #editProfilePlaceholder::-webkit-input-placeholder {
    font-size: 14px; }
  #editProfilePlaceholder::-ms-input-placeholder {
    font-size: 14px; }
  #editProfilePlaceholder::placeholder {
    font-size: 14px; }
  #editProfileCC {
    height: 300px; } }

.pagesp {
  display: flex;
  align-items: center;
  margin: 20px 80px 0 80px;
  height: 80vh;
  max-width: 100vw;
  overflow-x: scroll;
  overflow-y: visible; }

.pagesp::-webkit-scrollbar {
  width: 0px;
  background: transparent;
  /* make scrollbar transparent */ }

.pagesc {
  margin: 0 100px; }

@media (min-width: 425px) and (max-width: 768px) {
  .pagesp {
    display: flex;
    align-items: center;
    margin: 10px 40px 0 40px;
    max-width: 100vw;
    overflow-x: scroll;
    overflow-y: visible; }
  .pagesc {
    margin: 0 60px; } }

@media (min-width: 320px) and (max-width: 425px) {
  .pagesp {
    display: flex;
    align-items: center;
    margin: 10px 20px 0 20px;
    max-width: 100vw;
    overflow-x: scroll;
    overflow-y: visible; }
  .pagesc {
    margin: 0 20px; } }

@media screen and (max-width: 320px) {
  .pagesp {
    display: flex;
    align-items: center;
    margin: 20px 5px 0 5px;
    max-width: 100vw;
    overflow-x: scroll;
    overflow-y: visible; }
  .pagesc {
    margin: 0 5px; } }

.spagep {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 600px;
  width: 500px;
  margin: 0 auto;
  background: rgba(90, 95, 110, 0.748);
  box-shadow: 15px 15px 10px rgba(0, 0, 0, 0.33);
  -webkit-animation: appearspage 1s ease-in-out 0s 1;
          animation: appearspage 1s ease-in-out 0s 1; }

@-webkit-keyframes appearspage {
  0% {
    -webkit-transform: translate(-300px, 0);
            transform: translate(-300px, 0); }
  50% {
    -webkit-transform: translate(50px, 0);
            transform: translate(50px, 0); }
  100% { } }

@keyframes appearspage {
  0% {
    -webkit-transform: translate(-300px, 0);
            transform: translate(-300px, 0); }
  50% {
    -webkit-transform: translate(50px, 0);
            transform: translate(50px, 0); }
  100% { } }

.spagec {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .spagec .spagecc {
    font-size: 2rem;
    text-shadow: 5px 5px 1px rgba(20, 25, 30, 0.81);
    color: white; }
  .spagec .spagecc2 {
    margin: 0;
    font-size: 12px;
    margin-bottom: 10px;
    text-shadow: 5px 5px 1px rgba(20, 25, 30, 0.81);
    color: white; }
  .spagec img {
    height: 500px;
    width: 450px; }
  .spagec .deleteBtn, .spagec .deleteBtn:hover, .spagec .likebtn:hover, .spagec .unlikebtn:hover, .spagec .likebtn, .spagec .unlikebtn {
    cursor: pointer;
    font-size: 10px;
    background: rgba(0, 0, 0, 0);
    box-shadow: 2px 2px 1px white;
    text-shadow: 2px 2px 1px rgba(255, 255, 255, 0.459);
    border: none;
    border-radius: 5px 5px;
    color: white;
    font-family: 'Permanent Marker', cursive;
    width: 20px;
    height: 20px;
    -webkit-transition: .8s;
    transition: .8s;
    -webkit-transform: translate(0px, -5px);
            transform: translate(0px, -5px); }
  .spagec .deleteBtn:hover, .spagec .likebtn:hover, .spagec .unlikebtn:hover {
    background: rgba(0, 0, 0, 0.858); }
  .spagec .newpage {
    width: 200px;
    height: 40px;
    font-size: 1.5rem;
    margin: 10px 50px;
    border: none;
    border-top-right-radius: 5px 5px;
    border-bottom-left-radius: 5px 5px;
    background: rgba(80, 80, 80, 0.639);
    color: white;
    box-shadow: 2px 2px 1px white;
    font-family: 'Permanent Marker', cursive; }
  .spagec .upload-button {
    cursor: pointer;
    width: 200px;
    height: 30px;
    margin: 0 100px;
    font-size: 20px;
    background: rgba(0, 0, 0, 0.858);
    box-shadow: 2px 2px 1px white;
    text-shadow: 2px 2px 1px rgba(255, 255, 255, 0.459);
    border: none;
    border-radius: 10px 10px;
    color: white;
    font-family: 'Permanent Marker', cursive;
    -webkit-transition: .8s;
    transition: .8s; }
  .spagec .upload-button:hover {
    height: 40px;
    width: 220px; }
  .spagec .newpagediv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
  .spagec .upload {
    color: rgba(255, 255, 255, 0.486); }
  .spagec .pagefooter {
    height: 15px;
    width: 450px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-shadow: 5px 5px 1px rgba(20, 25, 30, 0.81);
    color: white; }
    .spagec .pagefooter > div {
      display: flex;
      align-items: center; }
  .spagec .likebtn, .spagec .unlikebtn {
    height: 0;
    width: 0;
    font-size: 20px;
    border: none;
    box-shadow: none;
    margin-left: 5px;
    -webkit-transform: translate(0, -15px);
            transform: translate(0, -15px); }
  .spagec .likebtn:hover, .spagec .unlikebtn:hover {
    background: none;
    box-shadow: none;
    font-size: 20px;
    -webkit-transform: scale(2);
            transform: scale(2); }

@media (min-width: 426px) and (max-width: 768px) {
  .spagep {
    height: 500px;
    width: 400px; }
  .spagec img {
    height: 400px;
    width: 350px;
    margin-bottom: 0; } }

@media screen and (max-width: 425px) {
  .spagep {
    height: 450px;
    width: 300px;
    margin-right: 10px; }
  .spagec .spagecc {
    font-size: 20px; }
  .spagec .upload {
    width: 280px; }
    .spagec .upload > div {
      width: 100%; }
      .spagec .upload > div > button {
        margin: 0 0 10px 0; }
  .spagec img {
    height: 350px;
    width: 275px; }
  .spagec .pagefooter {
    width: 300px;
    font-size: 15px; }
    .spagec .pagefooter span {
      margin-left: 10px; }
    .spagec .pagefooter .unlikebtn {
      font-size: 10px;
      -webkit-transform: translate(0, -7px);
              transform: translate(0, -7px); }
    .spagec .pagefooter .likebtn, .spagec .pagefooter .unlikebtn {
      font-size: 10px;
      -webkit-transform: translate(0, -7px);
              transform: translate(0, -7px); } }

.commentsp {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 600px;
  width: 500px;
  margin: 0 auto;
  background: rgba(90, 95, 110, 0.748);
  box-shadow: 15px 15px 10px rgba(0, 0, 0, 0.33);
  -webkit-animation: appearcoms 1s ease-in-out 0s 1;
          animation: appearcoms 1s ease-in-out 0s 1; }

@-webkit-keyframes appearcoms {
  0% {
    height: 0;
    width: 0; }
  100% {
    height: 600px;
    width: 500px; } }

@keyframes appearcoms {
  0% {
    height: 0;
    width: 0; }
  100% {
    height: 600px;
    width: 500px; } }

.commentsc {
  height: 550px;
  width: 450px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-shadow: 3px 3px 2px rgba(0, 0, 0, 0.33);
  text-align: center;
  font-size: 20px;
  -webkit-animation: appearcomsa 1s ease-in-out 0s 1;
          animation: appearcomsa 1s ease-in-out 0s 1; }

@-webkit-keyframes appearcomsa {
  0% {
    font-size: 0; }
  80% {
    font-size: 0; } }

@keyframes appearcomsa {
  0% {
    font-size: 0; }
  80% {
    font-size: 0; } }
  .commentsc .cmntsec {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    width: 100%;
    -webkit-animation: appearcomsab 1s ease-in-out 0s 1;
            animation: appearcomsab 1s ease-in-out 0s 1; }

@-webkit-keyframes appearcomsab {
  0% {
    height: 0;
    width: 0; }
  80% {
    height: 0;
    width: 0; } }

@keyframes appearcomsab {
  0% {
    height: 0;
    width: 0; }
  80% {
    height: 0;
    width: 0; } }
    .commentsc .cmntsec .usermsgbox {
      background: rgba(255, 255, 255, 0.344);
      border-bottom-left-radius: 15px 15px;
      border-bottom-right-radius: 15px 15px;
      border-top-right-radius: 15px 15px;
      margin: 5px 0;
      max-width: 80%;
      min-height: -webkit-fit-content;
      min-height: -moz-fit-content;
      min-height: fit-content;
      overflow-wrap: break-word;
      align-self: flex-start;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: left; }
      .commentsc .cmntsec .usermsgbox hr {
        margin: 1px auto;
        width: 100px;
        border: .5px solid black; }
      .commentsc .cmntsec .usermsgbox .cmntfield {
        padding: 0 10px 5px 10px; }
      .commentsc .cmntsec .usermsgbox .picnuser {
        padding: 0 10px 0 0;
        display: flex;
        justify-content: flex-start;
        align-items: center; }
      .commentsc .cmntsec .usermsgbox .compic1 {
        height: 40px;
        width: 40px;
        border-radius: 50%;
        padding: 5px; }
      .commentsc .cmntsec .usermsgbox .compuser {
        margin: 0 0 0 5px; }
    .commentsc .cmntsec .msgbox {
      background: rgba(54, 54, 54, 0.776);
      border-bottom-left-radius: 15px 15px;
      border-bottom-right-radius: 15px 15px;
      border-top-left-radius: 15px 15px;
      color: white;
      margin: 5px 0;
      max-width: 80%;
      min-height: -webkit-fit-content;
      min-height: -moz-fit-content;
      min-height: fit-content;
      overflow-wrap: break-word;
      align-self: flex-end;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: right; }
      .commentsc .cmntsec .msgbox hr {
        margin: 1px auto;
        width: 100px;
        border: .5px solid black; }
      .commentsc .cmntsec .msgbox .cmntfield {
        padding: 0 10px 5px 10px; }
      .commentsc .cmntsec .msgbox .picnuser {
        padding: 0 0 0 10px;
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-start;
        align-items: center; }
      .commentsc .cmntsec .msgbox .compic1 {
        height: 40px;
        width: 40px;
        border-radius: 50%;
        padding: 5px; }
      .commentsc .cmntsec .msgbox .compuser {
        margin: 0 5px 0 0; }
  .commentsc .cmntsec::-webkit-scrollbar {
    width: 0px;
    background: transparent;
    /* make scrollbar transparent */ }
  .commentsc .isTyping {
    -webkit-animation: typing 500ms ease-in 1;
            animation: typing 500ms ease-in 1; }

@-webkit-keyframes typing {
  0% {
    font-size: 0; }
  100% {
    font-size: 16px; } }

@keyframes typing {
  0% {
    font-size: 0; }
  100% {
    font-size: 16px; } }
  .commentsc .notTyping {
    height: 20px;
    display: none; }
  .commentsc input {
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
    border: none;
    border-top-right-radius: 5px 5px;
    border-bottom-left-radius: 5px 5px;
    background: rgba(80, 80, 80, 0.639);
    color: white;
    box-shadow: 2px 2px 1px white;
    height: 50px;
    width: 400px;
    font-size: 2rem;
    font-family: 'Permanent Marker', cursive;
    -webkit-animation: appearcomsabc 1s ease-in-out 0s 1;
            animation: appearcomsabc 1s ease-in-out 0s 1; }

@-webkit-keyframes appearcomsabc {
  0% {
    -webkit-transform: translate(0, 1000px);
            transform: translate(0, 1000px);
    height: 0;
    width: 0; }
  80% {
    -webkit-transform: translate(0, 1000px);
            transform: translate(0, 1000px);
    height: 0;
    width: 0; } }

@keyframes appearcomsabc {
  0% {
    -webkit-transform: translate(0, 1000px);
            transform: translate(0, 1000px);
    height: 0;
    width: 0; }
  80% {
    -webkit-transform: translate(0, 1000px);
            transform: translate(0, 1000px);
    height: 0;
    width: 0; } }
  .commentsc .submitbtn {
    cursor: pointer;
    margin-top: 10px;
    font-size: 20px;
    background: rgba(0, 0, 0, 0.858);
    box-shadow: 2px 2px 1px white;
    text-shadow: 2px 2px 1px rgba(255, 255, 255, 0.459);
    border: none;
    border-radius: 10px 10px;
    color: white;
    font-family: 'Permanent Marker', cursive;
    width: 100px;
    height: 30px;
    -webkit-transition: .8s;
    transition: .8s;
    -webkit-animation: appearcomsabcd 1s ease-in-out 0s 1;
            animation: appearcomsabcd 1s ease-in-out 0s 1; }

@-webkit-keyframes appearcomsabcd {
  0% {
    -webkit-transform: translate(0, 1100px);
            transform: translate(0, 1100px);
    font-size: 0;
    height: 0;
    width: 0; }
  80% {
    -webkit-transform: translate(0, 1100px);
            transform: translate(0, 1100px);
    font-size: 0;
    height: 0;
    width: 0; } }

@keyframes appearcomsabcd {
  0% {
    -webkit-transform: translate(0, 1100px);
            transform: translate(0, 1100px);
    font-size: 0;
    height: 0;
    width: 0; }
  80% {
    -webkit-transform: translate(0, 1100px);
            transform: translate(0, 1100px);
    font-size: 0;
    height: 0;
    width: 0; } }
  .commentsc .submitbtn:hover {
    width: 110px;
    height: 40px; }

@media screen and (max-width: 425px) {
  .commentsp {
    height: 450px;
    width: 300px; }
  @-webkit-keyframes appearcoms {
    100% {
      height: 450px;
      width: 300px; } }
  @keyframes appearcoms {
    100% {
      height: 450px;
      width: 300px; } }
    .commentsp > .commentsc {
      height: 400px;
      width: 280px; }
      .commentsp > .commentsc > .cmntinput {
        width: 280px;
        height: 75px; }
        .commentsp > .commentsc > .cmntinput > input {
          width: 200px;
          height: 30px; } }

.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 80px; }

.answerdiv {
  color: rgba(0, 0, 0, 0);
  font-size: 1px;
  -webkit-transition: 1s;
  transition: 1s; }

.revealdiv {
  color: white;
  font-size: 24px;
  -webkit-animation: answer 2s linear 1;
          animation: answer 2s linear 1; }

@-webkit-keyframes answer {
  0% {
    font-size: 0; }
  50% {
    -webkit-transform: rotateY(360deg);
            transform: rotateY(360deg); }
  100% {
    -webkit-transform: rotateY(720deg);
            transform: rotateY(720deg); } }

@keyframes answer {
  0% {
    font-size: 0; }
  50% {
    -webkit-transform: rotateY(360deg);
            transform: rotateY(360deg); }
  100% {
    -webkit-transform: rotateY(720deg);
            transform: rotateY(720deg); } }

.navp {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  width: 100%;
  background: rgba(54, 54, 54, 0.776);
  margin: 0 auto;
  box-shadow: 10px 5px 10px rgba(0, 0, 0, 0.584); }

.navc {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 0 20px; }
  .navc .navlinks {
    cursor: pointer;
    margin: 0 20px;
    text-decoration: none;
    color: white;
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg);
    font-size: 1.25rem;
    text-shadow: 10px 10px rgba(0, 0, 0, 0.351);
    -webkit-transition: 1s;
    transition: 1s; }
  .navc .navlinksL {
    margin: 0 20px;
    text-decoration: none;
    color: white;
    font-size: 1.25rem;
    text-shadow: 10px 10px rgba(0, 0, 0, 0.351);
    -webkit-transition: 1s;
    transition: 1s; }
    .navc .navlinksL .logoimg {
      height: 80px;
      width: 80px;
      box-shadow: 10px 10px rgba(0, 0, 0, 0.351);
      border-radius: 50%;
      -webkit-transition: 1s;
      transition: 1s; }
  .navc .logoimg:hover {
    background: black;
    box-shadow: 1px 1px 2px red, 0 0 3em blue, 0 0 0.2em blue; }
  .navc .navlinks:hover {
    font-size: 1.4rem;
    color: black;
    text-shadow: 1px 1px 2px red, 0 0 3em blue, 0 0 0.2em blue; }

.mobileTabs {
  display: none; }

@media (min-width: 425px) and (max-width: 768px) {
  .navp {
    height: 60px; }
  .navc .navlinks {
    margin: 0 12px;
    font-size: .9rem; }
  .navc .navlinksL .logoimg {
    height: 50px;
    width: 50px; } }

@media screen and (max-width: 425px) {
  .navp {
    height: 15vh; }
  .navc:nth-child(2) {
    display: none; }
  .navc {
    margin: 0; }
    .navc .navlinks {
      margin: 0 8px;
      -webkit-transform: rotate(-30deg);
              transform: rotate(-30deg);
      font-size: .6rem; }
    .navc .navlinksL .logoimg {
      height: 60px;
      width: 60px; }
    .navc .navlinks:hover {
      font-size: .7rem; }
  .mobileTabs {
    display: block; }
    .mobileTabs > .openMobileTab {
      font-size: 50px; }
    .mobileTabs > .tabList {
      position: absolute;
      z-index: 1;
      background-color: rgba(0, 0, 0, 0.95);
      height: 85vh;
      width: 100vw;
      -webkit-transform: translate(-84.5vw, 7px);
              transform: translate(-84.5vw, 7px);
      display: flex;
      flex-direction: column;
      align-items: center; }
      .mobileTabs > .tabList > .navlinks {
        cursor: pointer;
        margin: 30px 20px;
        text-decoration: none;
        color: white;
        -webkit-transform: rotate(-10deg);
                transform: rotate(-10deg);
        font-size: 1.25rem;
        text-shadow: 10px 10px rgba(0, 0, 0, 0.351);
        -webkit-transition: 1s;
        transition: 1s; }
    .mobileTabs .hideTabList {
      display: none; } }

