@import url('https://fonts.googleapis.com/css?family=Permanent+Marker');


$shadow: rgba(0, 0, 0, 0.33);
$back-sqr:rgba(90, 95, 110, 0.748);
$text-shadow:  rgba(20, 25, 30, 0.81);


.createepicp{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 600px;
    width: 500px;
    margin: 0 auto;
    // border: 1px solid rgba(255, 255, 255, 0.789);
    background: $back-sqr;
    // background: linear-gradient(0.25turn,rgba(0, 0, 0, 0.938),rgba(255, 255, 255, 0.604),rgba(0, 0, 0, 0.938));
    box-shadow: 15px 15px 10px $shadow;
    animation: appear 1s ease-in-out 0s 1;
    overflow: hidden;
    @keyframes appear {
        0% {
          height: 0;
          width: 0;
        }
        // 30% {
        //   font-size: 15px;
        // }
        100% {
            height: 600px;
            width: 500px;
        }
      }
}
.createepicc, .upload{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-shadow: 3px 3px 2px $shadow;
    text-align: center;
    font-size: 20px;

    .createh1{
        font-size: 3rem;
        text-shadow: 5px 5px 1px $text-shadow;
        text-align: center;
        color: rgb(255, 255, 255);
    }
    .createspan{
        font-size: 28px;
        text-shadow: 5px 5px 1px $text-shadow;
        color: white;
    }
    input{
        margin-bottom: 10px; 
        text-align: center;
        border: none;
        border-top-right-radius: 5px 5px;
        border-bottom-left-radius: 5px 5px;
        background: rgba(80, 80, 80, 0.639);
        color: white;
        box-shadow: 2px 2px 1px white;
        height: 50px;
        width: 400px;
        font-size: 2rem;
        font-family: 'Permanent Marker', cursive;
    }
    .upload-button{
        cursor: pointer;
        margin-top: 20px;
        margin-bottom: 20px;
        font-size: 20px;
        background: rgba(0, 0, 0, 0.858);
        box-shadow: 2px 2px 1px white;
        text-shadow: 2px 2px 1px rgba(255, 255, 255, 0.459);
        border: none;
        border-radius: 10px 10px;
        color: white;
        font-family: 'Permanent Marker', cursive;
        width: 150px;
        height: 30px;
        transition: .8s;
    }
     .upload-button:hover{
                width: 180px;
                height: 40px;
                // font-size: 
            }
    .upload{
                color: rgba(255, 255, 255, 0.486);
            }
}

@media (min-width: 426px) and (max-width: 768px){
    .createepicp{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 550px;
        width: 400px;
        margin: 0 auto;
        // border: 1px solid rgba(255, 255, 255, 0.789);
        background: $back-sqr;
        // background: linear-gradient(0.25turn,rgba(0, 0, 0, 0.938),rgba(255, 255, 255, 0.604),rgba(0, 0, 0, 0.938));
        box-shadow: 15px 15px 10px $shadow;
        animation: appear 1s ease-in-out 0s 1;
        overflow: hidden;
        @keyframes appear {
            0% {
              height: 0;
              width: 0;
            }
            // 30% {
            //   font-size: 15px;
            // }
            100% {
                height: 550px;
                width: 400px;
            }
          }
    }
    .createepicc{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-shadow: 3px 3px 2px $shadow;
    
        .createh1{
            font-size: 3rem;
            text-shadow: 2px 2px 1px white;
            text-align: center;
        }
        input{
            margin-bottom: 10px; 
            border: none;
            border-top-right-radius: 5px 5px;
            border-bottom-left-radius: 5px 5px;
            background: rgba(80, 80, 80, 0.639);
            color: white;
            box-shadow: 2px 2px 1px white;
            height: 30px;
            width: 300px;
            font-size: 2rem;
            font-family: 'Permanent Marker', cursive;
        }
        
    }
}

// @media (min-width: 320px) and (max-width: 425px){
//     .createepicp{
//         display: flex;
//         justify-content: center;
//         align-items: center;
//         height: 550px;
//         width: 400px;
//         margin: 0 auto;
//         // border: 1px solid rgba(255, 255, 255, 0.789);
//         background: $back-sqr;
//         // background: linear-gradient(0.25turn,rgba(0, 0, 0, 0.938),rgba(255, 255, 255, 0.604),rgba(0, 0, 0, 0.938));
//         box-shadow: 15px 15px 10px $shadow;
//         animation: appear 1s ease-in-out 0s 1;
//         overflow: hidden;
//         @keyframes appear {
//             0% {
//               height: 0;
//               width: 0;
//             }
//             // 30% {
//             //   font-size: 15px;
//             // }
//             100% {
//                 height: 550px;
//                 width: 400px;
//             }
//           }
//     }
//     .createepicc{
//         display: flex;
//         flex-direction: column;
//         justify-content: center;
//         align-items: center;
//         text-shadow: 3px 3px 2px $shadow;
    
//         .createh1{
//             font-size: 3rem;
//             text-shadow: 2px 2px 1px white;
//             text-align: center;
//         }
//         input{
//             margin-bottom: 10px; 
//             border: none;
//             border-top-right-radius: 5px 5px;
//             border-bottom-left-radius: 5px 5px;
//             background: rgba(80, 80, 80, 0.639);
//             color: white;
//             box-shadow: 2px 2px 1px white;
//             height: 30px;
//             width: 300px;
//             font-size: 2rem;
//             font-family: 'Permanent Marker', cursive;
//         }
//         // p{
//         //     margin-bottom: 250px;
//         //     text-shadow: 2px 2px 1px white;
    
//         // }
//     }
// }

@media screen and (max-width: 425px){
    .createepicp{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 450px;
        width: 300px;
        margin: 0 auto;
        // border: 1px solid rgba(255, 255, 255, 0.789);
        background: $back-sqr;
        // background: linear-gradient(0.25turn,rgba(0, 0, 0, 0.938),rgba(255, 255, 255, 0.604),rgba(0, 0, 0, 0.938));
        box-shadow: 15px 15px 10px $shadow;
        animation: appear 1s ease-in-out 0s 1;
        overflow: hidden;
        @keyframes appear {
            0% {
              height: 0;
              width: 0;
            }
            // 30% {
            //   font-size: 15px;
            // }
            100% {
                height: 450px;
                width: 300px;
            }
          }
    }
    .createepicc{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-shadow: 3px 3px 2px $shadow;
    
        .createh1{
            font-size: 2rem;
            // text-shadow: 2px 2px 1px white;
            text-align: center;
        }
        .createspan{
            font-size: 1.5rem;
        }
        input{
            margin-bottom: 10px; 
            border: none;
            border-top-right-radius: 5px 5px;
            border-bottom-left-radius: 5px 5px;
            background: rgba(80, 80, 80, 0.639);
            color: white;
            box-shadow: 2px 2px 1px white;
            height: 30px;
            width: 160px;
            font-size: 2rem;
            font-family: 'Permanent Marker', cursive;
        }
        br{
            display: none;
        }
        // p{
        //     margin-bottom: 250px;
        //     text-shadow: 2px 2px 1px white;
    
        // }
    }
}