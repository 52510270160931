@import url('https://fonts.googleapis.com/css?family=Permanent+Marker');

$shadow: rgba(0, 0, 0, 0.33);
$back-sqr:rgba(90, 95, 110, 0.748);
$text-shadow:  rgba(20, 25, 30, 0.81);
@function myCalc($num, $num2){
    @return $num + $num2
  }

.profilep{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 600px;
    width: 500px;
    margin: 0 auto;
    background: $back-sqr;
    box-shadow: 15px 15px 10px $shadow;
    animation: appearpro 1s ease-in-out 0s 1;
    // overflow: hidden;

    @keyframes appearpro {
        0% {
        
          height: 0px;
          width: 0px;
          font-size: 0px;
          border-radius: 50%;
        }
        80% {
          font-size: 0px;
        }
        100% {
            height: 600px;
            width: 500px;
        }
      }
}
.profilec{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-shadow: 5px 5px 1px $text-shadow;
    color: white;
    font-size: 24px;
    h3,h4{
        margin: 10px auto 0 auto;
    }
    div > div {
        text-align: center;
    }
    .profileInfo{
        @extend .profilec;
        flex-direction: row;
        justify-content: space-evenly;
        overflow-y: scroll;
        max-height: 300px;
        width: 450px;
        
    }

    .postcc{
        margin-bottom: 20px;
    }
    #mobilePicture{
        display: none;
    }
 
    .diamond, .dia {
        margin: 0 auto;
        transform-origin: 50% 50%;
        overflow: hidden;
        width: 200px;
        height: 200px;
      }
      .diamond {
        transform: rotate(45deg) translateY(-25px) translateX(-25px);
      }
      .diamond .dia {
        width: 380px;
        height: 400px;
        transform: rotate(-45deg);
        // position: relative;

      }
      .diamond img {
        width: 100%;
        height: auto;
        // transform: translate(0,-100px);
        // transform: scale();
        position: relative;
        left: 7px;
        top: -100px
        // margin: 0 auto;
      }

    .editpic{
        cursor: pointer;
        z-index: 1000;
        font-size: 10px;
        background: rgba(0, 0, 0, 0);
        box-shadow: 2px 2px 1px white;
        text-shadow: 2px 2px 1px rgba(255, 255, 255, 0.459);
        border: none;
        border-radius: 5px 5px;
        color: white;
        font-family: 'Permanent Marker', cursive;
        width: 40px;
        height: 20px;
        transition: .8s;
        transform: translate(0px,-80px);
    }
    .editpic:hover{
        font-size: 10px;
        background: rgba(0, 0, 0, 0.858);
        box-shadow: 2px 2px 1px white;
        text-shadow: 2px 2px 1px rgba(255, 255, 255, 0.459);
        border: none;
        border-radius: 5px 5px;
        color: white;
        font-family: 'Permanent Marker', cursive;
        width: 40px;
        height: 20px;
        transition: .8s;
    }
    .ceditpic{
        cursor: pointer;
        z-index: 0;
        font-size: 10px;
        margin: 0 5px;
        background: rgba(0, 0, 0, 0);
        box-shadow: 2px 2px 1px white;
        text-shadow: 2px 2px 1px rgba(255, 255, 255, 0.459);
        border: none;
        border-radius: 5px 5px;
        color: white;
        font-family: 'Permanent Marker', cursive;
        width: 50px;
        height: 20px;
        transition: .8s;
    }
    .ceditpic:hover{
        font-size: 10px;
        background: rgba(0, 0, 0, 0.858);
        box-shadow: 2px 2px 1px white;
        text-shadow: 2px 2px 1px rgba(255, 255, 255, 0.459);
        border: none;
        border-radius: 5px 5px;
        color: white;
        font-family: 'Permanent Marker', cursive;
        width: 50px;
        height: 20px;
        transition: .8s;
    }
    .cceditpic{
        cursor: pointer;
        z-index: 0;
        font-size: 10px;
        margin: 0 5px;
        background: rgba(0, 0, 0, 0);
        box-shadow: 2px 2px 1px white;
        text-shadow: 2px 2px 1px rgba(255, 255, 255, 0.459);
        border: none;
        border-radius: 5px 5px;
        color: white;
        font-family: 'Permanent Marker', cursive;
        width: 20px;
        height: 20px;
        transition: .8s;
    }
    .cceditpic:hover{
        font-size: 10px;
        background: rgba(0, 0, 0, 0.858);
        box-shadow: 2px 2px 1px white;
        text-shadow: 2px 2px 1px rgba(255, 255, 255, 0.459);
        border: none;
        border-radius: 5px 5px;
        color: white;
        font-family: 'Permanent Marker', cursive;
        width: 20px;
        height: 20px;
        transition: .8s;
    }
    input{
        height: 20px;
        width: 150px;
        font-size: 20px;
        border: none;
        border-top-left-radius: 5px 5px;
        border-bottom-right-radius: 5px 5px;
        background: rgba(80, 80, 80, 0.639);
        color: white;
        box-shadow: 2px 2px 1px white;
        font-family: 'Permanent Marker', cursive;
    }
    input::placeholder{
        color: white;
    }
    .editdiv{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .hideContent{
        display: none;
    }
    .showContent{
        height: 220vh;
        width: 100vw;
        position: absolute;
        background-color: rgba(26, 26, 46, 0.794);
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        .showContentc{
            position: absolute;
            z-index: 2;
            height: 80vh;
            width: 80vw;
            background: $back-sqr;
            margin-top: 100px;
            display: flex;
            flex-direction: column;
            // justify-content: space-evenly;
            align-items: center;
            overflow-y: scroll;
            hr{
                width: 500px;
                color: white;
            }
            > .showContentcc {
                height: 300px;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-evenly;     
            }
            
        }
        .showContentc::-webkit-scrollbar {
            width: 0px;
            background: transparent; /* make scrollbar transparent */
         }
    }
    .toggleContent{
        cursor: pointer;
        width: 100px;
        height: 30px;
        margin: 0 100px;
        font-size: 20px;
        background: rgba(0, 0, 0, 0.858);
        box-shadow: 2px 2px 1px white;
        text-shadow: 2px 2px 1px rgba(255, 255, 255, 0.459);
        border: none;
        border-radius: 10px 10px;
        color: white;
        font-family: 'Permanent Marker', cursive;
        transition: .8s;
        overflow: hidden;
        margin: 10px;
        transform: translate(0,18px);
    }
    .toggleContent:hover{
        width: 220px;
    }
    .toggleContent2{
        @extend .toggleContent;
        position: absolute;
        z-index: 3;
        transform: translate(0,510px);
    }
    .toggleContent3{
        cursor: pointer;
        z-index: 0;
        margin: 0 5px;
        background: rgba(0, 0, 0, 0);
        box-shadow: 2px 2px 1px white;
        text-shadow: 2px 2px 1px rgba(255, 255, 255, 0.459);
        border: none;
        border-radius: 5px 5px;
        color: white;
        font-family: 'Permanent Marker', cursive;
        transition: .8s;
        font-size: 12px;
        width: 60px;
        margin: 10px auto 0 auto;
        transform: translate(0,10px);
    } .toggleContent3:hover{
        background: rgba(0, 0, 0, 0.858);
    }

    .profileEdit{
        @extend .showContent;
    }
    .profileEditClosed{
        display: none;
    }
}
#mobileContentBtn{
    display: none;
}


@media (min-width: 426px) and (max-width: 768px){
    .profilep{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 550px;
        width: 400px;
        margin: 0 auto;
        background: $back-sqr;
        box-shadow: 15px 15px 10px $shadow;
        animation: appearpro 1s ease-in-out 0s 1;

           
        @keyframes appearpro {
            0% {
              height: 0px;
              width: 0px;
              font-size: 0px;
              border-radius: 50%;
            }
            
            80% {
              font-size: 0px;
            }
            100% {
                height: 550px;
                width: 400px;
            }
          }
    }
    .profilec{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-shadow: 3px 3px 2px $shadow;
    
        .postcc{
            margin-bottom: 20px;
        }
    }
}

// @media (min-width: 320px) and (max-width: 425px){
//     .profilep{
//         display: flex;
//         flex-direction: row;
//         justify-content: center;
//         align-items: center;
//         height: 500px;
//         width: 370px;
//         margin: 0 auto;
//         background: $back-sqr;
//         box-shadow: 15px 15px 10px $shadow;
//         animation: appearpro 1s ease-in-out 0s 1;
//         overflow: hidden;
//             h1{
//                 font-size: 3rem;    
//                 text-shadow: 2px 2px 1px white;
//             }
//         @keyframes appearpro {
//             0% {
//               height: 0px;
//               width: 0px;
//               font-size: 0px;
//               border-radius: 50%;
//             }
            
//             80% {
//               font-size: 0px;
//             }
//             100% {
//                 height: 500px;
//                 width: 370px;
//             }
//           }
//     }
//     .profilec{
//         display: flex;
//         flex-direction: column;
//         justify-content: center;
//         align-items: center;
//         text-shadow: 3px 3px 2px $shadow;
//     }
// }

@media screen and (max-width: 425px){
    .profilep{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 450px;
        width: 300px;
        margin: 0 auto;
        background: $back-sqr;
        box-shadow: 15px 15px 10px $shadow;
        animation: appearpro 1s ease-in-out 0s 1;
        overflow: hidden;
        // padding-top: 50px;
            h1{
                font-size: 3rem;    
                text-shadow: 2px 2px 1px white;
            }
        @keyframes appearpro {
            0% {
              height: 0px;
              width: 0px;
              font-size: 0px;
              border-radius: 50%;
            }
            
            80% {
              font-size: 0px;
            }
            100% {
                height: 450px;
                width: 300px;
            }
          }
    }
    .profilec{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        text-shadow: 3px 3px 2px $shadow;
        >.diamond, .dia {
            display: none;
          }
          .diamond {
            display: none;
          }
          .diamond .dia {
           display: none;
          }
        >.diamond img {
            display: none;
          }
        #mobilePicture{
            display: block;
            height: 130px;
            width: 130px;
            border-radius: 50%;
            margin-bottom: 10px;
        }
        #profileEdit{
            height: 100vh;
            width: 100vw;
            transform: translate(0,-90px);
        }
        .showContent{
            height: 100vh;
            width: 100vw;
            align-items: flex-start;
            transform: translate(0,-90px);
            >.showContentc{
                margin-top: 40px;
                display: block;
                // transform: translate(0,-50px);
            }
            >.toggleContent2{
                display: block;
                // transform: translate(0,-200px);
            }
        }
    }
    .profileInfo{
        flex-direction: column;
        overflow-y: scroll;
        max-height: 400px;
        width: 280px;
    }
    .profileBio{
        flex-direction: column;
        max-height: 400px;
        width: 280px;
    }
    
    #mobileContentBtn{
        display: block;
        margin: 0 auto 10px auto;
        cursor: pointer;
        width: 100px;
        height: 30px;
        background: rgba(0, 0, 0, 0);
        box-shadow: 2px 2px 1px white;
        text-shadow: 2px 2px 1px rgba(255, 255, 255, 0.459);
        border: none;
        border-radius: 5px 5px;
        color: white;
        font-family: 'Permanent Marker', cursive;
        transition: .8s;
        font-size: 12px;
        transform: translate(0,18px);
    }
    .toggleContent{
        display: none;
    }
    
    
}