@import url('https://fonts.googleapis.com/css?family=Permanent+Marker');


$shadow: rgba(0, 0, 0, 0.33);
$back-sqr:rgba(90, 95, 110, 0.748);
$text-shadow:  rgba(20, 25, 30, 0.81);


.commentsp{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 600px;
    width: 500px;
    margin: 0 auto;
    background: $back-sqr;
    box-shadow: 15px 15px 10px $shadow;
    animation: appearcoms 1s ease-in-out 0s 1;
    @keyframes appearcoms {
        0% {
          height: 0;
          width: 0;
        }
        100% {
            height: 600px;
            width: 500px;
        }
      }
}
.commentsc{
    height: 550px;
    width: 450px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-shadow: 3px 3px 2px $shadow;
    text-align: center;
    font-size: 20px;
    animation: appearcomsa 1s ease-in-out 0s 1;
    @keyframes appearcomsa {
        0% {
            font-size: 0
        }
        80% {
            font-size: 0
        }
      }

    .cmntsec{
        display: flex;
        flex-direction: column;
        overflow-y: scroll;
        width: 100%;
        animation: appearcomsab 1s ease-in-out 0s 1;
        @keyframes appearcomsab {
            0% {
                height: 0;
                width: 0
            }
            80% {
                height: 0;
                width: 0
            }
          }

        .usermsgbox{
            background: rgba(255, 255, 255, 0.344);
            border-bottom-left-radius: 15px 15px;
            border-bottom-right-radius: 15px 15px;
            border-top-right-radius: 15px 15px;
            margin: 5px 0;
            max-width: 80%;
            min-height: fit-content;
            overflow-wrap: break-word;
            align-self: flex-start;
            // overflow-y: scroll;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: left;
            hr{
                margin: 1px auto;
                width: 100px;
                border: .5px solid black;
                // box-shadow: 10px 0 2px black, -10px 0 2px black;
            }
            .cmntfield{
                padding: 0 10px 5px 10px;
            }
            .picnuser{
                padding: 0 10px 0 0;
                display:flex;
                justify-content: flex-start;
                align-items:center
            }
            .compic1 {
                height: 40px;
                width: 40px;
                border-radius: 50%;
                padding: 5px;
            }
            .compuser {
                margin: 0 0 0 5px;
            }
        }
        .msgbox{
            background: rgba(54, 54, 54, 0.776);
            border-bottom-left-radius: 15px 15px;
            border-bottom-right-radius: 15px 15px;
            border-top-left-radius: 15px 15px;
            color: white;
            margin: 5px 0;
            max-width: 80%;
            min-height: fit-content;
            overflow-wrap: break-word;
            align-self: flex-end;
            // overflow-y: scroll;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: right;
            hr{
                margin: 1px auto;
                width: 100px;
                border: .5px solid black;
                // box-shadow: 10px 0 2px black, -10px 0 2px black;
            }
            .cmntfield{
                padding: 0 10px 5px 10px;
            }
            .picnuser{
                padding: 0 0 0 10px;
                display:flex;
                flex-direction: row-reverse;
                justify-content: flex-start;
                align-items:center
            }
            .compic1 {
                height: 40px;
                width: 40px;
                border-radius: 50%;
                padding: 5px;
            }
            .compuser {
                margin: 0 5px 0 0;
            }

        }
       

    }
    .cmntsec::-webkit-scrollbar {
        width: 0px;
        background: transparent; /* make scrollbar transparent */
     }
    // .cmntinput{
    //     // position: sticky;
    // }
    .isTyping {
        animation: typing 500ms ease-in 1;
        @keyframes typing {
            0%{
                font-size: 0
            }
            100%{
                font-size: 16px;
            }
        }
    }
    .notTyping{
        height: 20px;
        display: none;
    }

    input{
        margin-top: 10px; 
        margin-bottom: 10px; 
        text-align: center;
        border: none;
        border-top-right-radius: 5px 5px;
        border-bottom-left-radius: 5px 5px;
        background: rgba(80, 80, 80, 0.639);
        color: white;
        box-shadow: 2px 2px 1px white;
        height: 50px;
        width: 400px;
        font-size: 2rem;
        font-family: 'Permanent Marker', cursive;
        animation: appearcomsabc 1s ease-in-out 0s 1;
        @keyframes appearcomsabc {
            0% {
                transform: translate(0,1000px);
                height: 0;
                width: 0
            }
            80% {
                transform: translate(0,1000px);
                height: 0;
                width: 0
            }
          }
    }
    .submitbtn{
        cursor: pointer;
        margin-top: 10px;
        // margin-bottom: 10px;
        font-size: 20px;
        background: rgba(0, 0, 0, 0.858);
        box-shadow: 2px 2px 1px white;
        text-shadow: 2px 2px 1px rgba(255, 255, 255, 0.459);
        border: none;
        border-radius: 10px 10px;
        color: white;
        font-family: 'Permanent Marker', cursive;
        width: 100px;
        height: 30px;
        transition: .8s;
        animation: appearcomsabcd 1s ease-in-out 0s 1;
        @keyframes appearcomsabcd {
            0% {
                transform: translate(0,1100px);
                font-size: 0;
                height: 0;
                width: 0
            }
            80% {
                transform: translate(0,1100px);
                font-size: 0;
                height: 0;
                width: 0
            }
          }
    }
     .submitbtn:hover{
                width: 110px;
                height: 40px;
                // font-size: 
            }
    // .cmntfield{
    //     overflow-y: scroll;
    //     max-width: 400px;
    // }
}

@media screen and (max-width: 425px){
    .commentsp{
        height: 450px;
        width: 300px;
        @keyframes appearcoms {
            100% {
                height: 450px;
                width: 300px;
            }
          }
        >.commentsc{
            height: 400px;
            width: 280px;
            >.cmntinput{
                width: 280px;
                height: 75px;
                >input{
                    width: 200px;
                    height: 30px;
                }
            }
        }
    }
}
