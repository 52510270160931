

.pagesp {
    display: flex;
    // justify-content: space-evenly;
    align-items: center;
    margin: 20px 80px 0 80px;
    // height: calc(100vh-150px);
    height: 80vh;
    max-width: 100vw;
    overflow-x: scroll;
    overflow-y: visible;
}
.pagesp::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
 }
.pagesc{
    margin: 0 100px;
}

@media (min-width: 425px) and (max-width: 768px){
    .pagesp {
        display: flex;
        align-items: center;
        margin: 10px 40px 0 40px;
        max-width: 100vw;
        overflow-x: scroll;
        overflow-y: visible;
    }
    .pagesc{
        margin: 0 60px;
    }
    
}
@media (min-width: 320px) and (max-width: 425px){
    .pagesp {
        display: flex;
        align-items: center;
        margin: 10px 20px 0 20px;
        max-width: 100vw;
        overflow-x: scroll;
        overflow-y: visible;
    }
    .pagesc{
        margin: 0 20px;
    }
}

@media screen and (max-width: 320px){
    .pagesp {
        display: flex;
        align-items: center;
        margin: 20px 5px 0 5px;
        max-width: 100vw;
        overflow-x: scroll;
        overflow-y: visible;
    }
    .pagesc{
        margin: 0 5px;
    }
}