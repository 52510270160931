.profileContentP{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 250px;
    width: 250px;
    background: rgba(30, 29, 54, 0.575);
    margin: 20px 15px;
    .profileContentC{
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        >h2{
            margin: 0
        }
        >img{
            height: 150px;
            width: 150px;
            margin-bottom: 20px;
        }
        > .contentPageImg{
            @extend img;
            margin-bottom: 0;
        }
        > div{
            @extend .profileContentC;
            justify-content: center;
            > p{
                margin: 10px;
                font-size: 12px;
            }
        }

    }
}