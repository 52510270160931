

.navp{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100px;
    width: 100%;
    background: rgba(54, 54, 54, 0.776);
    margin: 0 auto;
    box-shadow: 10px 5px 10px rgba(0, 0, 0, 0.584);
}

.navc {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 0 20px;
    

    .navlinks{
        cursor: pointer;
        margin: 0 20px;
        text-decoration: none;
        color: white;
        transform: rotate(-10deg);
        font-size: 1.25rem;
        text-shadow: 10px 10px rgba(0, 0, 0, 0.351);
        transition: 1s;
    }
    .navlinksL{
        margin: 0 20px;
        text-decoration: none;
        color: white;
        // transform: rotate(-10deg);
        font-size: 1.25rem;
        text-shadow: 10px 10px rgba(0, 0, 0, 0.351);
        transition: 1s;
        .logoimg{
            height: 80px;
            width: 80px;
            box-shadow: 10px 10px rgba(0, 0, 0, 0.351);
            border-radius: 50%;
            transition: 1s;
        }      
    }
    .logoimg:hover{
        background: black;
        box-shadow: 1px 1px 2px red, 0 0 3em blue, 0 0 0.2em blue;
    }
    
    .navlinks:hover{
        font-size: 1.4rem;
        color: black;
        text-shadow: 1px 1px 2px red, 0 0 3em blue, 0 0 0.2em blue;
    }
}
.mobileTabs{
    display: none;
}



@media (min-width: 425px) and (max-width: 768px){
    .navp{
        height: 60px;
    }
    
    .navc { 
        .navlinks{
            margin: 0 12px;
            font-size: .9rem;     
                  
        }
        .navlinksL{
            .logoimg{
                height: 50px;
                width: 50px;
            } 
        }
         
    }
}

// @media (min-width: 320px) and (max-width: 425px){
//     .navp{
//         height: 60px;
//     }
    
//     .navc {
//         margin: 0 10px;
    
//         .navlinks{
//             transform: rotate(-20deg);
//             font-size: .5rem;
//         }
//         .navlinksL{
//             .logoimg{
//                 height: 40px;
//                 width: 40px;
//             } 
//         }
//         .navlinks:hover{
//             font-size: .8rem;
//         }
//     }
// }

@media screen and (max-width: 425px){
    .navp{
        height: 15vh;
    }
    .navc:nth-child(2){
        display: none;
    }
    
    .navc {
        margin: 0;
        .navlinks{
            margin: 0 8px;
            transform: rotate(-30deg);
            font-size: .6rem;
        }
        .navlinksL{
            .logoimg{
                height: 60px;
                width: 60px;
            } 
        }
        .navlinks:hover{
            font-size: .7rem;
        }
    }
    .mobileTabs{
        display: block;
        >.openMobileTab{
            font-size: 50px;
        }
        >.tabList{
            position: absolute;
            z-index: 1;
            background-color: rgba(0, 0, 0, 0.95);
            height: 85vh;
            width: 100vw;
            transform: translate(-84.5vw,7px);
            display: flex;
            flex-direction: column;
            // justify-content: center;
            align-items: center;
            > .navlinks {
                cursor: pointer;
                margin: 30px 20px;
                text-decoration: none;
                color: white;
                transform: rotate(-10deg);
                font-size: 1.25rem;
                text-shadow: 10px 10px rgba(0, 0, 0, 0.351);
                transition: 1s;
            }
        }
        .hideTabList{
            display: none;
        }
    }
}