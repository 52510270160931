@import url('https://fonts.googleapis.com/css?family=Permanent+Marker');

$shadow: rgba(0, 0, 0, 0.33);
$back-sqr:rgba(90, 95, 110, 0.748);
$text-shadow:  rgba(20, 25, 30, 0.81);


.signupp{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 600px;
    width: 500px;
    margin: 0 auto;
    background: $back-sqr;
    box-shadow: 15px 15px 10px $shadow;
    animation: appearsign 1s ease-in-out 0s 1;
    // overflow-y: scroll;
        h1{
            font-size: 3rem;    
            text-shadow: 5px 5px 1px $text-shadow;
            color: white;
            margin-bottom: 50px;
            animation: appearsigna 1s ease-in-out 0s 1;
            @keyframes appearsigna {
                0% {
                  font-size: 0px;
                }
                80% {
                  font-size: 0px;
                }
              }
        }
    @keyframes appearsign {
        0% {
          height: 0px;
          width: 0px;
        }
        100% {
            height: 600px;
            width: 500px;
        }
      }
}
.signupc{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-shadow: 3px 3px 2px $shadow;
    animation: appearsignab 1s ease-in-out 0s 1;
    @keyframes appearsignab {
        0% {
          font-size: 0px;
        }
        80% {
          font-size: 0px;
        }
      }

    .signcc{
        height: 420px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        font-size: 24px;
        color: white;
        text-shadow: 5px 5px 1px $text-shadow;
        margin: 0 20px;
        animation: appearsignac 1s ease-in-out 0s 1;
        @keyframes appearsignac {
            0% {
              font-size: 0px;
            }
            80% {
              font-size: 0px;
            }
          }

        input, textarea{
            margin-bottom: 20px; 
            font-size: 20px;
            border: none;
            border-top-right-radius: 5px 5px;
            border-bottom-left-radius: 5px 5px;
            background: rgba(80, 80, 80, 0.639);
            color: white;
            box-shadow: 2px 2px 1px white;
            font-family: 'Permanent Marker', cursive;
            animation: appearsignabc 1s ease-in-out 0s 1;
            @keyframes appearsignabc {
                0% {
                    transform: translate(0,1000px);
                    height: 0;
                    width: 0
                }
                80% {
                    transform: translate(0,1000px);
                    height: 0;
                    width: 0
                }
              }
        }
        input{
            height: 20px;
            width: 150px;
        }
        textarea{
            width: 150px;
        }

        .submitForm{
            cursor: pointer;
            font-size: 20px;
            background: rgba(0, 0, 0, 0.858);
            box-shadow: 2px 2px 1px white;
            text-shadow: 2px 2px 1px rgba(255, 255, 255, 0.459);
            border: none;
            border-radius: 10px 10px;
            color: white;
            font-family: 'Permanent Marker', cursive;
            width: 100px;
            height: 30px;
            margin-bottom: 30px;
            transition: .8s;
            animation: appearsignabcd 1s ease-in-out 0s 1;
            @keyframes appearsignabcd {
                0% {
                    transform: translate(0,1000px);
                    height: 0;
                    width: 0
                }
                80% {
                    transform: translate(0,1000px);
                    height: 0;
                    width: 0
                }
              }
        }
        .submitForm:hover{
                    width: 130px;
                    height: 40px;
        }
        .resetForm{
            @extend .submitForm;
            cursor: pointer;
            height: 30px;
            width: 70px;
        }
        .buttonDivSpace{
            height: 50px;
        }
   }

}

@media (min-width: 425px) and (max-width: 768px){
    .signupp{
        height: 550px;
        width: 400px;
        animation: appearsign 1s ease-in-out 0s 1;
        @keyframes appearsign {
            0% {
              height: 0px;
              width: 0px;
              font-size: 0px;
              border-radius: 50%;
            }
            80% {
              font-size: 0px;
            }
            100% {
                height: 550px;
                width: 400px;
            }
          }
    }
    .signupc{
        .signcc{
            font-size: 18px;
    
            input, textarea{
                margin-bottom: 10px; 
            }
       }
    }
}

// @media (min-width: 320px) and (max-width: 425px){
//     .signupp{
//         height: 550px;
//         width: 400px;
//         animation: appearsign 1s ease-in-out 0s 1;
//         @keyframes appearsign {
//             0% {
//               height: 0px;
//               width: 0px;
//               font-size: 0px;
//               border-radius: 50%;
//             }
//             80% {
//               font-size: 0px;
//             }
//             100% {
//                 height: 550px;
//                 width: 400px;
//             }
//           }
//     }
// }

@media screen and (max-width: 425px){
    .signupp{
        height: 450px;
        width: 300px;
        animation: appearsign 1s ease-in-out 0s 1;
        h1{
            font-size: 2rem;
            margin-top: 0;
            margin-bottom: 0;
        }
        @keyframes appearsign {
            0% {
              height: 0px;
              width: 0px;
              font-size: 0px;
              border-radius: 50%;
            }
            
            80% {
              font-size: 0px;
            }
            100% {
                height: 450px;
                width: 300px;
            }
          }
    }
    .signupc{
        .signcc{
            font-size: 16px;
            label{
                text-align: center;
            }
            input, textarea{
                width: 100px;
            }
       }
    }
}