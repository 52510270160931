.submitForm{
    cursor: pointer;
    font-size: 20px;
    background: rgba(0, 0, 0, 0.858);
    box-shadow: 2px 2px 1px white;
    text-shadow: 2px 2px 1px rgba(255, 255, 255, 0.459);
    border: none;
    border-radius: 10px 10px;
    color: white;
    font-family: 'Permanent Marker', cursive;
    width: 100px;
    height: 30px;
    margin-bottom: 30px;
    transition: .8s;
    animation: appearsignabcd 1s ease-in-out 0s 1;
    @keyframes appearsignabcd {
        0% {
            transform: translate(0,1000px);
            height: 0;
            width: 0
        }
        80% {
            transform: translate(0,1000px);
            height: 0;
            width: 0
        }
      }
}
.submitForm:hover{
            width: 130px;
            height: 30px;
}
#editProfilePlaceholder::placeholder{
    color: rgba(255,255,255,0.25);    
}

@media screen and (max-width: 425px){
    #mobileEditProfile{
        height: 70vh;
        margin-top: 60px;
    }
    #editProfileH1{
        font-size: 2rem;
        text-shadow: none;
        margin-bottom: 20px;
    }
    #editProfilePlaceholder::placeholder{
        font-size: 14px;
    }
    #editProfileCC{
        height: 300px;
    }
}