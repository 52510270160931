@import url('https://fonts.googleapis.com/css?family=Permanent+Marker');

$shadow: rgba(0, 0, 0, 0.33);
$back-sqr: rgba(128, 128, 128, 0.604);
$text-shadow:  rgba(20, 25, 30, 0.81);


.postp{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 600px;
    width: 500px;
    margin: 0 auto;
    // border: 1px solid rgba(255, 255, 255, 0.789);
    background: rgba(90, 95, 110, 0.748);
    // background: linear-gradient(0.25turn,rgba(0, 0, 0, 0.938),rgba(255, 255, 255, 0.604),rgba(0, 0, 0, 0.938));
    box-shadow: 15px 15px 10px $shadow;
    animation: appearpost 1s ease-in-out 0s 1;
       
    @keyframes appearpost {
        0% {
            transform: translate(-300px,0);
        }
        
        50% {
            transform: translate(50px,0);
        }
        100% {
        }
      }
}
.postc{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .postcc{
        margin-bottom: 20px;
        font-size: 2rem;
        text-shadow: 5px 5px 1px $text-shadow;
        color: white;
    }

    img{
        height: 500px;
        width: 450px;
    }
    
    .deleteBtn{
            cursor: pointer;
            font-size: 10px;
            background: rgba(0, 0, 0, 0);
            box-shadow: 2px 2px 1px white;
            text-shadow: 2px 2px 1px rgba(255, 255, 255, 0.459);
            border: none;
            border-radius: 5px 5px;
            color: white;
            font-family: 'Permanent Marker', cursive;
            width: 20px;
            height: 20px;
            transition: .8s;
            transform: translate(0px,-1px);
    }
      .deleteBtn:hover{
            font-size: 10px;
            background: rgba(0, 0, 0, 0.858);
            box-shadow: 2px 2px 1px white;
            text-shadow: 2px 2px 1px rgba(255, 255, 255, 0.459);
            border: none;
            border-radius: 5px 5px;
            color: white;
            font-family: 'Permanent Marker', cursive;
            width: 20px;
            height: 20px;
            transition: .8s;
                }
    .footerdiv{
        height: 10px;
        width: 450px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: white;
        font-family: 'Permanent Marker', cursive;
        text-shadow: 2px 2px 1px $text-shadow;
    }
    .likebtn{
        @extend .deleteBtn;
        height: 0;
        width: 0;
        font-size: 20px;
        border: none;
        box-shadow: none;
        margin-left: 5px;
        transform: translate(0,-1px);
    } .likebtn:hover{
        @extend .deleteBtn:hover;
        background: none;
        box-shadow: none;
        font-size: 20px;
        transform: scale(2)
    }
    .unlikebtn{
        @extend .likebtn
    } .unlikebtn:hover {
        @extend .likebtn:hover
    }
    .postpages{
        margin-right: 10px;
    }

}
.mobiledelete{
    display: none;
}
#deleteBtnM{
    position: absolute;
    display: none;
}


@media (min-width: 425px) and (max-width: 768px){
    .postp{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 550px;
        width: 400px;
        margin: 0 auto;
        box-shadow: 15px 15px 10px $shadow;
        animation: appearpost 1s ease-in-out 0s 1;
        overflow: hidden;
         
    }
    .postc{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-shadow: 3px 3px 2px $shadow;
    
        .postcc{
            margin-bottom: 20px;
        }
        img{
            height: 350px;
            width: 300px;
            margin-bottom: 0;
        }
    }
}

// @media (min-width: 320px) and (max-width: 425px){
//     .postp{
//         display: flex;
//         flex-direction: row;
//         justify-content: center;
//         align-items: center;
//         height: 500px;
//         width: 370px;
//         margin: 0 auto;
//         box-shadow: 15px 15px 10px $shadow;
//     }
//     .postc{
//         display: flex;
//         flex-direction: column;
//         justify-content: center;
//         align-items: center;
//         text-shadow: 3px 3px 2px $shadow;
    
//         .postcc{
//             margin-bottom: 20px;
//         }
    
//         img{
//             height: 400px;
//             width: 350px;
//         }
//     }
// }

@media screen and (max-width: 425px){
    .postp{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 440px;
        width: 300px;
        margin: 0 auto;
        box-shadow: 15px 15px 10px $shadow;
        animation: appearpost 1s ease-in-out 0s 1;
    }
    .postc{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        text-shadow: 3px 3px 2px $shadow;
        .postpages {
            transform: translate(-40px,0)
        }
    
        .postcc{
            margin-bottom: 20px;
            font-size: 20px;
        }
    
        img{
            height: 350px;
            width: 280px;
        }
        .deleteBtn{
            cursor: pointer;
            font-size: 10px;
            background: rgba(0, 0, 0, 1);
            box-shadow: 2px 2px 1px white;
            text-shadow: 2px 2px 1px rgba(255, 255, 255, 0.459);
            border: none;
            border-radius: 5px 5px;
            color: white;
            font-family: 'Permanent Marker', cursive;
            width: 20px;
            height: 20px;
            transition: .8s;
            // transform: translate(135px,-5px);
        }
        .likebtn{
            font-size: 12px;
        }
        >.footerdiv{
            width: 300px;
            
            span{
                margin-left: 10px;
                margin-right: 0;
            }
        }
    }
    .mobiledelete{
        display: block;
    }
    #deleteBtnM{
        cursor: pointer;
        font-size: 10px;
        background: rgba(0, 0, 0, 1);
        box-shadow: 2px 2px 1px white;
        text-shadow: 2px 2px 1px rgba(255, 255, 255, 0.459);
        border: none;
        border-radius: 5px 5px;
        color: white;
        font-family: 'Permanent Marker', cursive;
        width: 20px;
        height: 20px;
        transition: .8s;
        position: static;
        display: block;
        transform: translate(-11px,0);
    }
    
}