@import url('https://fonts.googleapis.com/css?family=Permanent+Marker');

$shadow: rgba(0, 0, 0, 0.33);
$back-sqr:rgba(90, 95, 110, 0.748);
$text-shadow:  rgba(20, 25, 30, 0.81);




.forgotpasswordp{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 600px;
    width: 500px;
    margin: 0 auto;
    background: $back-sqr;
    box-shadow: 15px 15px 10px $shadow;
    animation: appearfp 1s ease-in-out 0s 1;
    // overflow-y: scroll;
        h1{
            font-size: 3rem;    
            text-shadow: 5px 5px 1px $text-shadow;
            color: white;
            margin-bottom: 50px;
            animation: appearfph1 1s ease-in-out 0s 1;
            @keyframes appearfph1 {
                0% {
                  font-size: 0px;
                }
                80% {
                  font-size: 0px;
                }
              }
        }
    @keyframes appearfp {
        0% {
          height: 0px;
          width: 0px;
        }
        100% {
            height: 600px;
            width: 500px;
        }
      }
}
.forgotpasswordc{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-shadow: 3px 3px 2px $shadow;
    animation: appearfpc 1s ease-in-out 0s 1;
    @keyframes appearfpc {
        0% {
          font-size: 0px;
        }
        80% {
          font-size: 0px;
        }
      }
    }
.exitfp, .submitfp{
    cursor: pointer;
    margin-top: 20px;
    font-size: 20px;
    background: rgba(0, 0, 0, 0.858);
    box-shadow: 2px 2px 1px white;
    text-shadow: 2px 2px 1px rgba(255, 255, 255, 0.459);
    border: none;
    border-radius: 10px 10px;
    color: white;
    font-family: 'Permanent Marker', cursive;
    width: 100px;
    height: 30px;
    transition: .8s;
}
.exitdiv{
  position: absolute;
  transform: translate(0px,310px)
}
.btndiv{
  height: 40px;
}



@media screen and (max-width: 425px){
  .forgotpasswordp{
    height: 450px;
    width: 300px;
    h1{
      font-size: 2rem;
    }
    @keyframes appearfp {
      0% {
        height: 0px;
        width: 0px;
      }
      100% {
          height: 450px;
          width: 300px;
      }
    }
  }
  .exitdiv{
    position: absolute;
    transform: translate(0px,232px)
  }

}