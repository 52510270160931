.card{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 80px;
}

.answerdiv{
    color: rgba(0,0,0,0);
    font-size: 1px;
    transition: 1s
}
.revealdiv{
    color: white;
    font-size: 24px;
    animation: answer 2s linear 1;
    @keyframes answer {
        0%{
            font-size: 0
        }
        50%{
            transform: rotateY(360deg)
        }
        100%{
            transform: rotateY(720deg)
        }
    }
}
