@import url('https://fonts.googleapis.com/css?family=Permanent+Marker');

$shadow: rgba(0, 0, 0, 0.33);
$back-sqr:rgba(90, 95, 110, 0.748);
$text-shadow:  rgba(20, 25, 30, 0.81);


.loginp{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 600px;
    width: 500px;
    margin: 0 auto;
    background: $back-sqr;
    box-shadow: 15px 15px 10px $shadow;
    animation: appearlog 1s ease-in-out 0s 1;
        h1{
            font-size: 3rem;    
            text-shadow: 5px 5px 1px $text-shadow;
            color: white;
            animation: appearlogabc 1s ease-in-out 0s 1;
            @keyframes appearlogabc {
                0% {
                  font-size: 0
                }
                80% {
                    font-size: 0
                }
              }
        }
    @keyframes appearlog {
        0% {
            bottom: 0;
          height: 0;
          width: 0;
          font-size: 0px;
          border-radius: 50%;
        }
        80% {
          font-size: 0px;
        }
        100% {
            height: 600px;
            width: 500px;
        }
      }
}
.loginc{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-shadow: 5px 5px 1px $text-shadow;
    color: white;
    font-size: 24px;
    margin-bottom: 150px;
    animation: appearlogabcd 1s ease-in-out 0s 1;
    @keyframes appearlogabcd {
        0% {
          font-size: 0
        }
        80% {
            font-size: 0
        }
      }
    

        input{
            margin-bottom: 10px; 
            font-size: 20px;
            height: 25px;
            width: 180px;
            border: none;
            border-top-right-radius: 5px 5px;
            border-bottom-left-radius: 5px 5px;
            background: rgba(80, 80, 80, 0.639);
            color: white;
            box-shadow: 2px 2px 1px white;
            font-family: 'Permanent Marker', cursive;
            animation: appearloga 1s ease-in-out 0s 1;
            @keyframes appearloga {
                0% {
                    transform: translate(0,1000px);
                  height: 0;
                  width: 0;
                }
                80% {
                    transform: translate(0,1000px);
                  height: 0;
                  width: 0;
                }
              }
        }

        button{
            cursor: pointer;
            margin-top: 20px;
            font-size: 20px;
            background: rgba(0, 0, 0, 0.858);
            box-shadow: 2px 2px 1px white;
            text-shadow: 2px 2px 1px rgba(255, 255, 255, 0.459);
            border: none;
            border-radius: 10px 10px;
            color: white;
            font-family: 'Permanent Marker', cursive;
            width: 100px;
            height: 30px;
            transition: .8s;
            animation: appearlogab 1s ease-in-out 0s 1;
            @keyframes appearlogab {
                0% {
                    transform: translate(0,1000px);
                  height: 0;
                  width: 0;
                }
                80% {
                    transform: translate(0,1000px);
                  height: 0;
                  width: 0;
                }
              }
        }
         button:hover{
                    width: 130px;
                    height: 40px;        
                }
        .fpbtn{
            cursor: pointer;
            z-index: 0;
            margin: 0 5px;
            background: rgba(0, 0, 0, 0);
            box-shadow: 2px 2px 1px white;
            text-shadow: 2px 2px 1px rgba(255, 255, 255, 0.459);
            border: none;
            border-radius: 5px 5px;
            color: white;
            font-family: 'Permanent Marker', cursive;
            transition: .8s;
            font-size: 12px;
            width: 130px;
            height: 30px;
            transform: translate(0,-15px)
        }
        .fpbtn:hover{
         background: rgba(0, 0, 0, 0.858);
         width: 130px;
         height: 30px;
        }
        
        .hideFP{
            display: none;
        }
        .showFP{
            height: 220vh;
            width: 100vw;
            position: absolute;
            background-color: rgba(26, 26, 46, 0.794);
            z-index: 1;
            display: flex;
            justify-content: center;
            align-items: center;
        }
}

@media (min-width: 425px) and (max-width: 768px){
    .loginp{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 550px;
        width: 400px;
        margin: 0 auto;
        // border: 1px solid rgba(255, 255, 255, 0.789);
        background: $back-sqr;
        // background: linear-gradient(0.25turn,rgba(0, 0, 0, 0.938),rgba(255, 255, 255, 0.604),rgba(0, 0, 0, 0.938));
        box-shadow: 15px 15px 10px $shadow;
        animation: appearlog 1s ease-in-out 0s 1;
        overflow: hidden;
            h1{
                font-size: 3rem;    
                text-shadow: 2px 2px 1px white;
            }
        @keyframes appearlog {
            0% {
                bottom: 0;
              height: 0;
              width: 0;
              font-size: 0px;
              border-radius: 50%;
            }
            
            80% {
              font-size: 0px;
            //   height: 250px;
            //   width: 250px;
                // margin-top: 150px;
            }
            100% {
                height: 550px;
                width: 400px;
                overflow: hidden;
    
            }
          }
    }
    .loginc{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-shadow: 3px 3px 2px $shadow;
        margin-bottom: 150px;
        
    
            input{
                margin-bottom: 10px; 
                border: none;
                border-top-right-radius: 5px 5px;
                border-bottom-left-radius: 5px 5px;
                background: rgba(80, 80, 80, 0.639);
                color: white;
                box-shadow: 2px 2px 1px white;
                font-family: 'Permanent Marker', cursive;
            }
    
            button{
                margin-top: 20px;
                font-size: 20px;
                background: rgba(0, 0, 0, 0.858);
                box-shadow: 2px 2px 1px white;
                text-shadow: 2px 2px 1px rgba(255, 255, 255, 0.459);
                border: none;
                border-radius: 10px 10px;
                color: white;
                font-family: 'Permanent Marker', cursive;
                width: 100px;
                height: 30px;
                transition: .8s;
            }
             button:hover{
                        width: 130px;
                        height: 40px;
                        // font-size: 
                    }
       
    }
}

// @media (min-width: 320px) and (max-width: 425px){
//     .loginp{
//         display: flex;
//         flex-direction: column;
//         justify-content: center;
//         align-items: center;
//         height: 550px;
//         width: 400px;
//         margin: 0 auto;
//         // border: 1px solid rgba(255, 255, 255, 0.789);
//         background: $back-sqr;
//         // background: linear-gradient(0.25turn,rgba(0, 0, 0, 0.938),rgba(255, 255, 255, 0.604),rgba(0, 0, 0, 0.938));
//         box-shadow: 15px 15px 10px $shadow;
//         animation: appearlog 1s ease-in-out 0s 1;
//         overflow: hidden;
//             h1{
//                 font-size: 3rem;    
//                 text-shadow: 2px 2px 1px white;
//             }
//         @keyframes appearlog {
//             0% {
//                 bottom: 0;
//               height: 0;
//               width: 0;
//               font-size: 0px;
//               border-radius: 50%;
//             }
            
//             80% {
//               font-size: 0px;
//             //   height: 250px;
//             //   width: 250px;
//                 // margin-top: 150px;
//             }
//             100% {
//                 height: 550px;
//                 width: 400px;
//                 overflow: hidden;
    
//             }
//           }
//     }
//     .loginc{
//         display: flex;
//         flex-direction: column;
//         justify-content: center;
//         align-items: center;
//         text-shadow: 3px 3px 2px $shadow;
//         margin-bottom: 150px;
        
    
//             input{
//                 margin-bottom: 10px; 
//                 border: none;
//                 border-top-right-radius: 5px 5px;
//                 border-bottom-left-radius: 5px 5px;
//                 background: rgba(80, 80, 80, 0.639);
//                 color: white;
//                 box-shadow: 2px 2px 1px white;
//                 font-family: 'Permanent Marker', cursive;
//             }
    
//             button{
//                 margin-top: 20px;
//                 font-size: 20px;
//                 background: rgba(0, 0, 0, 0.858);
//                 box-shadow: 2px 2px 1px white;
//                 text-shadow: 2px 2px 1px rgba(255, 255, 255, 0.459);
//                 border: none;
//                 border-radius: 10px 10px;
//                 color: white;
//                 font-family: 'Permanent Marker', cursive;
//                 width: 100px;
//                 height: 30px;
//                 transition: .8s;
//             }
//              button:hover{
//                         width: 130px;
//                         height: 40px;
//                         // font-size: 
//                     }
       
//     }
// }

@media screen and (max-width: 425px){
    .loginp{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 450px;
        width: 300px;
        margin: 0 auto;
        // border: 1px solid rgba(255, 255, 255, 0.789);
        background: $back-sqr;
        // background: linear-gradient(0.25turn,rgba(0, 0, 0, 0.938),rgba(255, 255, 255, 0.604),rgba(0, 0, 0, 0.938));
        box-shadow: 15px 15px 10px $shadow;
        animation: appearlog 1s ease-in-out 0s 1;
        overflow: hidden;
            h1{
                font-size: 2rem;    
                // text-shadow: 2px 2px 1px white;
            }
        @keyframes appearlog {
            0% {
                bottom: 0;
              height: 0;
              width: 0;
              font-size: 0px;
              border-radius: 50%;
            }
            
            80% {
              font-size: 0px;
            //   height: 250px;
            //   width: 250px;
                // margin-top: 150px;
            }
            100% {
                height: 450px;
                width: 300px;
                overflow: hidden;
            }
          }
    }
    .loginc{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-shadow: 3px 3px 2px $shadow;
        margin-bottom: 150px;
        .showFP{
            height: 100vh;
            width: 100vw;
            transform: translate(0,30px);
        }
            input{
                margin-bottom: 10px; 
                width: 140px;
                border: none;
                border-top-right-radius: 5px 5px;
                border-bottom-left-radius: 5px 5px;
                background: rgba(80, 80, 80, 0.639);
                color: white;
                box-shadow: 2px 2px 1px white;
                font-family: 'Permanent Marker', cursive;
            }
    
            button{
                margin-top: 20px;
                font-size: 20px;
                background: rgba(0, 0, 0, 0.858);
                box-shadow: 2px 2px 1px white;
                text-shadow: 2px 2px 1px rgba(255, 255, 255, 0.459);
                border: none;
                border-radius: 10px 10px;
                color: white;
                font-family: 'Permanent Marker', cursive;
                width: 100px;
                height: 30px;
                transition: .8s;
            }
             button:hover{
                        width: 130px;
                        height: 40px;
                        // font-size: 
                    }
       
    }
}