@import url('https://fonts.googleapis.com/css?family=Permanent+Marker');
@import "display";

$shadow: rgba(0, 0, 0, 0.33);
$back-sqr: rgba(128, 128, 128, 0.604);
$text-shadow:  rgba(20, 25, 30, 0.81);
// @mixin flex {
//     display: flex
// }
@function myCalc($num, $num2){
    @return $num + $num2
  }

.spagep{
    @include flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: myCalc(300px,300px);
    width: 500px;
    margin: 0 auto;
    background: rgba(90, 95, 110, 0.748);
    box-shadow: 15px 15px 10px $shadow;
    animation: appearspage 1s ease-in-out 0s 1;
       
    @keyframes appearspage {
        0% {
            transform: translate(-300px,0);
        }
        
        50% {
            transform: translate(50px,0);
        }
        100% {
        }
      }
}
.spagec{
    @include flex;    
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .spagecc{
        font-size: 2rem;
        text-shadow: 5px 5px 1px $text-shadow;
        color: white;
    }
    .spagecc2{
        margin: 0;
        font-size: 12px;
        margin-bottom: 10px;
        text-shadow: 5px 5px 1px $text-shadow;
        color: white;
    }

    img{
        height: 500px;
        width: 450px;
    }
    .deleteBtn{
        cursor: pointer;
        font-size: 10px;
        background: rgba(0, 0, 0, 0);
        box-shadow: 2px 2px 1px white;
        text-shadow: 2px 2px 1px rgba(255, 255, 255, 0.459);
        border: none;
        border-radius: 5px 5px;
        color: white;
        font-family: 'Permanent Marker', cursive;
        width: 20px;
        height: 20px;
        transition: .8s;
        transform: translate(0px,-5px);
    }
    .deleteBtn:hover{
        @extend .deleteBtn;
        // font-size: 10px;
        background: rgba(0, 0, 0, 0.858);
        // box-shadow: 2px 2px 1px white;
        // text-shadow: 2px 2px 1px rgba(255, 255, 255, 0.459);
        // border: none;
        // border-radius: 5px 5px;
        // color: white;
        // font-family: 'Permanent Marker', cursive;
        // width: 20px;
        // height: 20px;
        // transition: .8s;
        }
    .newpage{
        width: 200px;
        height: 40px;
        font-size: 1.5rem;
        margin: 10px 50px; 
        border: none;
        border-top-right-radius: 5px 5px;
        border-bottom-left-radius: 5px 5px;
        background: rgba(80, 80, 80, 0.639);
        color: white;
        box-shadow: 2px 2px 1px white;
        font-family: 'Permanent Marker', cursive;
    }
    .upload-button{
        cursor: pointer;
        width: 200px;
        height: 30px;
        margin: 0 100px;
        font-size: 20px;
        background: rgba(0, 0, 0, 0.858);
        box-shadow: 2px 2px 1px white;
        text-shadow: 2px 2px 1px rgba(255, 255, 255, 0.459);
        border: none;
        border-radius: 10px 10px;
        color: white;
        font-family: 'Permanent Marker', cursive;
        transition: .8s;
    }
    .upload-button:hover{
        height: 40px;
        width: 220px;
    }
    .newpagediv{
        @include flex;        
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .upload{
        color: rgba(255, 255, 255, 0.486);
    }
    .pagefooter{
        height: 15px;
        width: 450px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-shadow: 5px 5px 1px $text-shadow;
        color: white;
        > div{
            display: flex;
            align-items: center;
        }
    }
    .likebtn{
        @extend .deleteBtn;
        height: 0;
        width: 0;
        font-size: 20px;
        border: none;
        box-shadow: none;
        margin-left: 5px;
        transform: translate(0,-15px);
    } .likebtn:hover{
        @extend .deleteBtn:hover;
        background: none;
        box-shadow: none;
        font-size: 20px;
        transform: scale(2)
    }
    .unlikebtn{
        @extend .likebtn
    } .unlikebtn:hover {
        @extend .likebtn:hover
    }
}

@media (min-width: 426px) and (max-width: 768px){
    .spagep{
        height: 500px;
        width: 400px;
    }
    .spagec{

        img{
            height: 400px;
            width: 350px;
            margin-bottom: 0;
        }
    }
}

// @media (min-width: 320px) and (max-width: 425px){
//    .spagep{
//        height: 425px;
//        width: 350px;
//    }
//    .spagec{
//        .spagecc{
//            font-size: 20px;
//        }
//        img{
//            height: 350px;
//            width: 300px;
//        }
//    }
// }

@media screen and (max-width: 425px){
    .spagep{
        height: 450px;
        width: 300px;
        margin-right: 10px;
    }
    .spagec{
        .spagecc{
            font-size: 20px;
        }
        .upload{
            width: 280px;
            >div{
                width:100%;
                >button{
                    margin: 0 0 10px 0;
                }
            }
        }
        img{
            height: 350px;
            width: 275px;
        }
        .pagefooter{
            width: 300px;
            font-size: 15px;
            span{
                margin-left: 10px;
            }
            .unlikebtn{
                font-size: 10px;
                transform: translate(0,-7px);
            }
            .likebtn{
                font-size: 10px;
                transform: translate(0,-7px);
            }
        }
    }
    
}